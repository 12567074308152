import { useParams } from 'react-router';
import { IonContent, IonIcon, IonItem, IonList, IonPage, IonText } from '@ionic/react';
import { useSelector } from 'react-redux';
import { allSessionsSelector, completeSessionsSelector, userSelector } from '../../store/slices';
import { useTranslation } from 'react-i18next';
import './SessionFullDetails.scoped.scss';
import React, { useEffect } from 'react';
import PercentageComponent from '../../components/PercentageComponent/PercentageComponent';
import { useAppDispatch } from '../../store/store';
import { replace } from 'redux-first-history';
import {
  DONT_WATCH_SUBJECT,
  STATUS_SESSION_COMPLETE,
  subjectLookerRelationshipOptions,
} from '../../appConfigurations';
import moment from 'moment';
import userDefaultAvatar from '../../images/user.svg';
import { SessionExtraKeys, TrialExtraKeys } from '../../models';
import completeIcon from '../../images/completeIcon.svg';
import TrialResults from '../../components/TrialResults/TrialResults';
import correctAnswer from '../../images/correct_answer.svg';
import skipAnswer from '../../images/skip_answer.svg';
import wrongAnswer from '../../images/wrong_answer.svg';
import BackButton from '../../components/BackButton/BackButton';

const SessionFullDetails: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>() || null;
  const dispatch = useAppDispatch();
  const allSessions = useSelector(allSessionsSelector);
  const completeSessions = useSelector(completeSessionsSelector);
  const sessions = [...allSessions!, ...completeSessions!] || [];
  const user = useSelector(userSelector);
  let session = sessions.find((session) => session.id === Number(id)) as SessionExtraKeys;
  const finishedDate = moment(session?.updated_at).format('llll');
  const userAvatar = user?.photo_path?.endsWith('/storage') ? userDefaultAvatar : user?.photo_path;

  session = {
    ...session,
    subjectLookerRelationship: subjectLookerRelationshipOptions.find(
      (relationship) => relationship.option === session?.subject_looker_relationship
    )?.text,
    participantRole: session?.looker?.user_id === user?.id ? t('Looker') : t('Subject'),
    statusIcon: session?.status! >= STATUS_SESSION_COMPLETE ? completeIcon : '',
    statusText: session?.status! >= STATUS_SESSION_COMPLETE ? t('Completed') : t('Canceled'),
  } as SessionExtraKeys;

  useEffect(() => {
    if (!sessions.length) {
      dispatch(replace('/welcome'));
    }
  }, [sessions.length, dispatch]);

  const trials =
    session?.trials?.map(
      (trial) =>
        ({
          ...trial,
          lookerWatchSubjectInstruction:
            trial.looker_watch_subject === DONT_WATCH_SUBJECT
              ? t('Don’t look at the subject')
              : t('Look at the subject'),
          icon:
            trial.subject_answer === trial.looker_watch_subject
              ? correctAnswer
              : trial.subject_answer! > 1 || trial.subject_answer === null
              ? skipAnswer
              : wrongAnswer,
        }) as TrialExtraKeys
    ) || [];

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <IonList lines="none" className="page-wrapper">
          <BackButton />
          <IonItem color="none" className="ion-no-padding ion-no-margin">
            <h1 className="session-full-details-header ion-no-margin ion-margin-top">
              {t(`Session 00${session?.id}`)}
            </h1>
          </IonItem>
          <IonItem color="none" className="ion-no-padding ion-no-margin">
            <IonText className="finished-date">{finishedDate}</IonText>
          </IonItem>
          <IonItem
            color="none"
            className="ion-no-padding ion-no-margin status-wrapper status-icon-item"
          >
            <IonIcon src={session.statusIcon} className="status-icon-size" />
            <h6 className="status-text">{session?.statusText}</h6>
          </IonItem>
          {session?.looker?.group && (
            <>
              <IonItem color="none" className="ion-no-padding ion-no-margin">
                <IonText className="session-full-details-text">{t('Group')}</IonText>
              </IonItem>
              <IonItem
                color="none"
                className="ion-no-padding ion-no-margin session-full-details-item"
              >
                <IonText className="session-full-details">{session?.looker?.group}</IonText>
              </IonItem>
              <hr />
            </>
          )}
          {session?.subject_looker_relationship && (
            <>
              <IonItem color="none" className="ion-no-padding ion-no-margin">
                <IonText className="session-full-details-text">
                  {t('You relationship with Looker')}
                </IonText>
              </IonItem>
              <IonItem
                color="none"
                className="ion-no-padding ion-no-margin session-full-details-item"
              >
                <IonText className="session-full-details">
                  {session?.subjectLookerRelationship}
                </IonText>
              </IonItem>
              <hr />
            </>
          )}
          <IonItem color="none" className="ion-no-padding ion-no-margin">
            <IonText className="session-full-details-text">{t('Your role')}</IonText>
          </IonItem>
          <IonItem color="none" className="ion-no-padding ion-no-margin session-full-details-item">
            <IonText className="session-full-details">{session?.participantRole}</IonText>
          </IonItem>
          <hr />
          <div className="percentage-component-spacer">
            <PercentageComponent
              icon={userAvatar}
              name={session?.subject?.name}
              answeredPercentage={session?.answered_percentage!}
              answeredCorrectly={session?.answered_correctly!}
              totalNoOfTrialsAnswered={session?.total_no_of_trials_answered!}
            />
          </div>
          <hr />
          <IonItem className="ion-no-padding ion-no-margin results-title">
            <IonText>{t('Answers')}</IonText>
          </IonItem>
          <IonItem className="ion-no-padding ion-no-margin results-how-you-did">
            <IonText>{t('See how you did')}</IonText>
          </IonItem>
          <TrialResults trials={trials} height="100%" sessionPage={true} />
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default SessionFullDetails;
