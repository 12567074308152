import { useTranslation } from 'react-i18next';
import { IonButton, IonItem, IonText } from '@ionic/react';
import WarningModalWrapper from '../WarningModalWrapper/WarningModalWrapper';
import './EndOfSessionModal.scoped.scss';
import '../WarningModal/WarningModal.scoped.scss';
import React from 'react';

const EndOfSessionModal = ({
  onDismiss,
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <WarningModalWrapper
      onDismiss={() => onDismiss(null, 'cancel')}
      closeButton={true}
      warningModal={false}
    >
      <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
        <h1>{t('Oops!')}</h1>
      </IonItem>
      <div className="text-container">
        <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
          <h6>{t('The session has been ended by the other person')}</h6>
        </IonItem>
        <IonItem
          lines="none"
          color="none"
          className="ion-no-padding ion-no-margin session-end-subtitle"
        >
          <IonText className="back-to-results">
            {t('We are taking you to the results page')}
          </IonText>
        </IonItem>
      </div>
      <IonItem
        lines="none"
        color="none"
        className="ion-no-padding ion-no-margin modal-buttons ok-btn"
      >
        <IonButton className="primary-type-button-small" onClick={() => onDismiss(null, 'accept')}>
          {t('Okay')}
        </IonButton>
      </IonItem>
    </WarningModalWrapper>
  );
};

export default EndOfSessionModal;
