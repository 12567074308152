import { IonContent, IonItem, IonList, IonPage, IonText, useIonModal } from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import './LetsConnectSubject.scoped.scss';
import { useAppDispatch } from '../../store/store';
import { resetAll, sessionCodeSelector, sessionIdSelector } from '../../store/slices';
import BackButton from '../../components/BackButton/BackButton';
import WarningModal from '../../components/WarningModal/WarningModal';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { useSelector } from 'react-redux';
import { SessionApi } from '../../store/api';
import { LOOKER_JOINED, REQUEST_LOOP_TIMEOUT } from '../../appConfigurations';
import { SessionIdRequest } from '../../models';
import { replace } from 'redux-first-history';

const LetsConnectSubject: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [present, dismiss] = useIonModal(WarningModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });
  const sessionId = useSelector(sessionIdSelector);
  const sessionCode = useSelector(sessionCodeSelector);

  const openModal = (back: Function) => {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'accept') {
          dispatch(resetAll());
          back();
        }
      },
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    const waitForLookerToJoin = async () => {
      try {
        const response = await dispatch(
          SessionApi.endpoints.getCurrentSession.initiate(
            { sessionId: sessionId } as SessionIdRequest,
            { forceRefetch: true }
          )
        ).unwrap();

        if (response?.status === LOOKER_JOINED) {
          dispatch(replace('/connection-successful-subject'));
        }
      } catch (error) {
        console.log(error);
      }

      if (timer) {
        timer = setTimeout(waitForLookerToJoin, REQUEST_LOOP_TIMEOUT);
      }
    };

    timer = setTimeout(waitForLookerToJoin, REQUEST_LOOP_TIMEOUT);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = null;
    };
  }, [dispatch, sessionId]);

  return (
    <IonPage>
      <IonContent className="subject-settings-bg">
        <IonList className="lets-connect-subject">
          <div className="btn-container">
            <BackButton conditionalNavigation={(back: Function) => openModal(back)} />
          </div>
          <div>
            <div className="page-title-container">
              <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
                <h4 className="page-title">{t('Subject')}</h4>
              </IonItem>
            </div>
            <div className="page-title-container">
              <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
                <h4 className="page-subtitle">{t("Let's connect")}</h4>
              </IonItem>
            </div>
            <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
              <IonText className="subtitle">
                {t('Here is your pairing QR code. Show it to the Looker for scanning.')}
              </IonText>
            </IonItem>
          </div>
          <div className="qr-code-wrapper">
            <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin qr-item">
              <QRCode value={sessionId?.toString() || ''} size={200} />
            </IonItem>
            <div className="qr-wrap">
              <IonItem
                lines="none"
                color="none"
                className="ion-no-padding ion-no-margin item-subtitle"
              >
                <IonText className="subtitle">{t('Or show them this code')}</IonText>
              </IonItem>
              <IonItem
                lines="none"
                color="none"
                className="ion-no-padding ion-no-margin custom-item-select"
              >
                <div className="ion-text-center session-code-container">
                  <span className="session-code-text">
                    {sessionCode.substring(0, sessionCode.length / 2)}
                  </span>
                  <span className="code-separator">-</span>
                  <span>{sessionCode.substring(sessionCode.length / 2)}</span>
                </div>
              </IonItem>
            </div>
          </div>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default LetsConnectSubject;
