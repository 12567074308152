import { useEffect } from 'react';

export type SoundKeys = 'countDownStarted' | 'startTrial' | 'endTrial' | 'endSession';

export type SoundType = {
  audio: HTMLAudioElement;
  wasLoaded: boolean;
  play: () => Promise<void>;
};

const generateSound = (path: string): SoundType => {
  const audio = new Audio(path);
  audio.autoplay = false;
  audio.preload = 'auto';

  return {
    audio,
    wasLoaded: false,
    play: () =>
      new Promise((resolve, reject) => {
        // console.log('Started: ', audio.src);

        // If song won't end after 5 seconds
        const timeout = setTimeout(resolve, audio.duration * 1000 + 1000);

        audio.onended = () => {
          resolve();
          clearTimeout(timeout);
          // console.log('Played:', audio.src);
        };
        audio.play().then(
          () => {
            // console.log('Playing: ', audio.src);
          },
          () => {
            // console.log('Could not play: ', audio.src);
            reject();
          }
        );
      }),
  };
};

export const soundFiles: {
  [Property in SoundKeys]: SoundType;
} = {
  countDownStarted: generateSound('/assets/countdown_start_sound.mp3'),
  startTrial: generateSound('/assets/start_trial_sound.wav'),
  endTrial: generateSound('/assets/end_trial_sound.wav'),
  endSession: generateSound('/assets/end_session_sound.mp3'),
};

const SoundLoader = () => {
  const loadSounds = () => {
    try {
      const soundsToHandle = Object.values(soundFiles).filter(
        (soundFiles) => !soundFiles.wasLoaded
      );

      // Detach event listener if everything is loaded
      if (!soundsToHandle.length) {
        // console.log('Finished loading sounds !!!');

        window.removeEventListener('click', loadSounds, false);
      } else {
        // console.log('Loading sounds!!!');

        soundsToHandle.forEach((sound) => {
          sound.audio.load();
          sound.audio.currentTime = 0;
          sound.wasLoaded = true;
        });
      }
    } catch (ex) {
      console.log(`Unable to load sounds. ${JSON.stringify(ex)}`);
    }
  };

  useEffect(() => {
    window.addEventListener('click', loadSounds, false);

    return () => {
      window.removeEventListener('click', loadSounds, false);
      // console.log('Sound loader was unloaded !!!!');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default SoundLoader;
