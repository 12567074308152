import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonText,
} from '@ionic/react';
import settingsIcon from '../../images/settings.svg';
import userDefaultAvatar from '../../images/user.svg';
import { useSelector } from 'react-redux';
import { allSessionsSelector, completeSessionsSelector, userSelector } from '../../store/slices';
import './Home.scoped.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/store';
import SessionBasicDetails from '../../components/SessionBasicDetails/SessionBasicDetails';
import { push } from 'redux-first-history';
import { ParticipantType, Session } from '../../models';
import { SessionApi } from '../../store/api';
import GeneralStats from '../../components/GeneralStats/GeneralStats';
import { useEndRunningSessions } from '../../hooks/useEndRunningSessionsHook';
import ArrowBackSvg from '../../images/back_button.svg';
import plusIcon from '../../images/plus-icon.svg';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { photo_path, name } = useSelector(userSelector) || {};
  const [renderView, setRenderView] = useState('all');
  const [participantType, setParticipantType] = useState<ParticipantType>('subject');
  const userAvatar = photo_path?.endsWith('/storage') ? userDefaultAvatar : photo_path;
  const dispatch = useAppDispatch();
  const [pageAll, setPageAll] = useState(1);
  const [pageCompleted, setPageCompleted] = useState(1);
  const [disableInfiniteScrollAll, setDisableInfiniteScrollAll] = useState(false);
  const [disableInfiniteScrollCompleted, setDisableInfiniteScrollCompleted] = useState(false);
  const allSessionsState = useSelector(allSessionsSelector) || [];
  const completeSessionsState = useSelector(completeSessionsSelector) || [];
  useEndRunningSessions();

  const getAllSessions = async (page?: number) => {
    if (!page) {
      page = pageAll;
    }

    try {
      const response = await dispatch(
        SessionApi.endpoints.listAllSessions.initiate(
          { page, participant: participantType },
          { forceRefetch: true }
        )
      ).unwrap();

      if (response.data.length) {
        setPageAll(page + 1);
      }

      if (!response.next_page_url) {
        setDisableInfiniteScrollAll(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCompleteSessions = async (page?: number) => {
    if (!page) {
      page = pageCompleted;
    }

    try {
      const response = await dispatch(
        SessionApi.endpoints.listCompleteSessions.initiate(
          { page, participant: participantType },
          { forceRefetch: true }
        )
      ).unwrap();

      if (response.data.length) {
        setPageCompleted(page + 1);
      }

      if (!response.next_page_url) {
        setDisableInfiniteScrollCompleted(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setPageAll(1);
    setPageCompleted(1);
    (renderView === 'all' ? getAllSessions : getCompleteSessions)(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantType]);

  useEffect(() => {
    (renderView === 'all' ? getAllSessions : getCompleteSessions)(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderView]);

  const handleBack = () => {
    dispatch(push('/home'));
  };

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <IonList lines="none" className="page-wrapper">
          <div className="user-header">
            <IonButton className="small-settings-button-light" onClick={() => handleBack()}>
              <IonIcon className="back-icon" src={ArrowBackSvg} />
            </IonButton>
            <IonButton
              className="small-settings-button-light"
              onClick={() => dispatch(push('/settings'))}
            >
              <IonImg src={settingsIcon} alt="settings icon" />
            </IonButton>
          </div>
          <div className="avatar-container">
            <IonAvatar onClick={() => dispatch(push('/my-profile'))}>
              <img src={userAvatar} alt="user avatar" className="user-icon" />
            </IonAvatar>
          </div>
          <IonItem color="none" className="ion-margin-bottom ion-no-padding">
            <div className="text-center">
              <IonText className="subtitle">{t('Welcome back!')}</IonText>
              <IonLabel className="user-name">{name ? `${name}` : t('Hello')}</IonLabel>
            </div>
          </IonItem>
          <IonItem color="none" className="ion-no-margin ion-no-padding ion-margin-bottom">
            <IonButton
              className="primary-type-button new-session-btn"
              onClick={() => dispatch(push('/new-session'))}
            >
              <IonImg className="plus-icon" src={plusIcon} alt="plus icon" />
              {t('New session')}
            </IonButton>
          </IonItem>
          <hr />
          <IonItem
            color="none"
            className="ion-no-margin ion-no-padding ion-margin-top session-header"
          >
            {t('This is how you did')}
          </IonItem>
          <IonSegment
            className="home-buttons"
            value={participantType}
            onIonChange={(e) => setParticipantType(e.detail.value as ParticipantType)}
          >
            <IonSegmentButton value={'subject' as ParticipantType}>
              <IonLabel>{t('Subject')}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={'looker' as ParticipantType}>
              <IonLabel>{t('Looker')}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="ion-padding-top"></div>
          <GeneralStats participant={participantType} />
          <hr />
          <IonItem
            color="none"
            className="ion-no-margin ion-no-padding ion-margin-top session-header"
          >
            {t('Sessions')}
          </IonItem>
          <IonSegment
            className="home-buttons"
            value={renderView}
            onIonChange={(e) => setRenderView(e.detail.value!)}
          >
            <IonSegmentButton value="all">
              <IonLabel>{t('All')}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="completed" className="complete-sessions">
              <IonLabel>{t('Completed')}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonList>
        <IonList className="sessions-list">
          {renderView === 'all'
            ? allSessionsState?.map((session: Session) => (
                <SessionBasicDetails session={session} key={session.id} />
              ))
            : completeSessionsState?.map((session: Session) => (
                <SessionBasicDetails session={session} key={session.id} />
              ))}
          {((renderView && !allSessionsState?.length && !completeSessionsState?.length) ||
            (renderView === 'completed' &&
              allSessionsState?.length &&
              !completeSessionsState?.length)) && (
            <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
              <IonText className="no-data">{t('No data')}</IonText>
            </IonItem>
          )}
          <IonInfiniteScroll
            position="bottom"
            disabled={
              renderView === 'all' ? disableInfiniteScrollAll : disableInfiniteScrollCompleted
            }
            onIonInfinite={(ev) => {
              renderView === 'all' ? getAllSessions() : getCompleteSessions();
              setTimeout(() => ev.target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent />
          </IonInfiniteScroll>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Home;
