import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonSlides,
  IonSlide,
  IonImg,
  IonIcon,
} from '@ionic/react';
import './HowOurAppWorks.scoped.scss';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store/store';
import { goBack } from 'redux-first-history';
import close from '../../images/close.svg';
import { useCreateSliderConfiguration } from '../../hooks/useCreateSliderConfiguration';
import arrowNext from '../../images/arrow-next.svg';

const HowOurAppWorks = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderForTutorial = useCreateSliderConfiguration();
  const slideOpts = {
    initialSlide: 0,
  };

  const handleSlideNext = () => {
    if (currentIndex < sliderForTutorial.length - 1) {
      if (slidesRef.current) {
        slidesRef.current.slideNext();
      }
    } else {
      dispatch(goBack());
    }
  };

  const handleSlideChange = async () => {
    const swiper = await slidesRef?.current?.getSwiper();
    const currentIndex = swiper.activeIndex;
    setCurrentIndex(currentIndex);
  };

  const onClose = () => {
    dispatch(goBack());
  };

  return (
    <IonPage>
      <IonContent className="start-page" scroll-y={false}>
        <IonGrid className="ion-no-padding how-to-grid">
          <IonRow className="ion-margin-top">
            <IonCol className="ion-no-margin ion-no-padding">
              <div className="header">
                <div>
                  {currentIndex > 0 && (
                    <>
                      <span className="ion-text-start tutorial-title">{t('TUTORIAL')}</span>
                      <h5 className="how-works-title">{t('How our app works')}</h5>
                    </>
                  )}
                  {currentIndex > 0 && (
                    <>
                      <IonRow>
                        <div className="tutorial-tips">
                          {t(`TIP ${currentIndex} of ${sliderForTutorial.length - 1}`)}
                        </div>
                      </IonRow>
                    </>
                  )}
                </div>
                <div className="close-icon">
                  <IonButton className="small-settings-button-light" onClick={() => onClose()}>
                    <div className="button-container">
                      <IonIcon src={close} />
                    </div>
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="slider-row">
            <IonSlides
              pager={true}
              options={slideOpts}
              className={`custom-slider ${!currentIndex && 'first-slide'}`}
              ref={slidesRef}
              onIonSlideDidChange={handleSlideChange}
            >
              {sliderForTutorial.map((sliderItem, index) => (
                <IonSlide key={index}>
                  <div className="slide-image-container">
                    <IonImg
                      src={sliderItem.image}
                      alt={sliderItem.imageAlt}
                      className="tutorial-image"
                    />
                  </div>
                  <div className="slide-text-container">
                    <h5 className="slider-title">{sliderItem.title}</h5>
                    <div className="placeholder-slider-text slider-subtitle">
                      {sliderItem.subtitle}
                    </div>
                  </div>
                </IonSlide>
              ))}
            </IonSlides>
            <div className="ion-no-padding tutorial-buttons">
              <IonButton class="secondary-type-button-small next-button" onClick={handleSlideNext}>
                <IonImg
                  src={arrowNext}
                  alt="connection successful icon"
                  className="connection-icon"
                ></IonImg>
              </IonButton>
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default HowOurAppWorks;
