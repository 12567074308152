import { useEffect } from 'react';
import { setAllowShareLocation, setLocationPermission, setParticipant } from '../../store/slices';
import { useAppDispatch } from '../../store/store';
import { PermissionState } from '@capacitor/core';

const CheckLocationPermissions: React.FC = () => {
  const dispatch = useAppDispatch();
  const updateHasLocationChanged = (permissionState: PermissionState) => {
    switch (permissionState) {
      case 'granted':
        dispatch(setLocationPermission('granted'));
        break;
      case 'denied':
        dispatch(setLocationPermission('denied'));
        dispatch(setParticipant({ latitude: null, longitude: null }));
        dispatch(setAllowShareLocation(false));
        break;
      case 'prompt':
        dispatch(setLocationPermission('prompt'));
        dispatch(setParticipant({ latitude: null, longitude: null }));
        dispatch(setAllowShareLocation(false));
        break;
    }
  };

  useEffect(() => {
    navigator.permissions &&
      navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
        updateHasLocationChanged(PermissionStatus.state);

        PermissionStatus.onchange = () => {
          updateHasLocationChanged(PermissionStatus.state);
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

export default CheckLocationPermissions;
