import { createSelector, createSlice } from '@reduxjs/toolkit';
import { APP_SETTINGS_STATE_NAME, AppSettingsState } from '../../models';

const AppSettingsInitialState = {
  numberOfTrials: 20,
  timeBoxedTrial: true,
  trialDuration: 8,
  skipTrial: false,
  language: 'en-US',
  playSounds: true,
  locationPermission: 'prompt',
} as AppSettingsState;

export const AppSettingsSlice = createSlice({
  name: APP_SETTINGS_STATE_NAME,
  initialState: AppSettingsInitialState,
  reducers: {
    setAppSettings: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    setLocationPermission: (state, { payload }) => {
      state.locationPermission = payload;
    },
  },
});

type PartialState = {
  [AppSettingsSlice.name]: AppSettingsState;
  [key: string]: any;
};

export const appSettingsSelector = createSelector(
  (state: PartialState) => state[AppSettingsSlice.name],
  (it) => it
);
export const locationPermissionSelector = createSelector(
  (state: PartialState) => state[AppSettingsSlice.name].locationPermission,
  (it) => it
);

export const { setAppSettings, setLocationPermission } = AppSettingsSlice.actions;
