import NoSleep from 'nosleep.js';
import { useEffect } from 'react';

const KeepScreenAwake: React.FC = () => {
  const noSleep = new NoSleep();

  useEffect(() => {
    const onClick = () => {
      noSleep.enable();

      window.removeEventListener('click', onClick, false);
    };
    window.addEventListener('click', onClick, false);

    return () => {
      noSleep.disable();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default KeepScreenAwake;
