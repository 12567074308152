import React from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonImg } from '@ionic/react';
import './SplashScreen.scoped.scss';
import { useTranslation } from 'react-i18next';
import eye from '../../images/staring-logo.svg';

const SplashScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent className="loading-page-1">
        <IonGrid>
          <div className="first-transition">
            <IonRow>
              <div className="app-title">
                <div className="staring-text turn-white phase-two">{t('STARING')}</div>
                <IonImg src={eye} alt="landing page logo" />
                <div className="app-text turn-white phase-two">{t('APP')}</div>
              </div>
            </IonRow>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SplashScreen;
