import { IonRow, IonText, useIonModal } from '@ionic/react';
import SessionIsStartingWrapper from '../../components/SessionIsStartingWrapper/SessionIsStartingWrapper';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import './SessionIsStartingSubject.scoped.scss';
import { useSelector } from 'react-redux';
import { blindfoldSelector, lookerSelector, resetAll, sessionIdSelector } from '../../store/slices';
import { useAppDispatch } from '../../store/store';
import { useEffect } from 'react';
import { replace } from 'redux-first-history';
import WarningModal from '../../components/WarningModal/WarningModal';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { SessionIdRequest } from '../../models';
import { useSubjectCancelMutation } from '../../store/api';

const SessionIsStartingSubject: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const looker = useSelector(lookerSelector);
  const hasBlindfold = useSelector(blindfoldSelector);
  const [subjectCancel] = useSubjectCancelMutation();
  const sessionId = useSelector(sessionIdSelector);

  const [present, dismiss] = useIonModal(WarningModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const openModal = (back: Function) => {
    present({
      onWillDismiss: async (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'accept') {
          const response = await subjectCancel({
            sessionId: sessionId,
          } as SessionIdRequest).unwrap();
          if (response) {
            dispatch(resetAll());
            back();
          }
        }
      },
    });
  };

  useEffect(() => {
    if (looker?.ready) {
      dispatch(replace('/get-ready-subject'));
    }
  }, [looker?.ready, dispatch]);

  return (
    <SessionIsStartingWrapper openModal={openModal}>
      <div>
        <IonRow className="subtitle ion-justify-content-center ion-no-padding ion-no-margin">
          {t('Waiting for Looker to start')}
        </IonRow>
        <IonRow className="session-additional-info">
          {hasBlindfold ? (
            <>
              <IonText className="">{t('Is your blindfold ready?')}</IonText>
              <IonText className="">{t('You have selected sounds')}</IonText>
            </>
          ) : (
            <></>
          )}
        </IonRow>
      </div>
      <IonRow className="ion-justify-content-center spinner-wrapper">
        <CircularProgress className="custom-spinner" />
      </IonRow>
    </SessionIsStartingWrapper>
  );
};

export default SessionIsStartingSubject;
