import SessionWrapper from '../../components/SessionWrapper/SessionWrapper';
import { useSelector } from 'react-redux';
import {
  currentTrialSelector,
  lookerSelector,
  subjectSelector,
  timeBoxedTrialSelector,
  trialDurationSelector,
} from '../../store/slices';
import { IonButton, IonImg, IonItem, IonList } from '@ionic/react';
import './LookerFlow.scoped.scss';
import { WATCH_SUBJECT } from '../../appConfigurations';
import { useTranslation } from 'react-i18next';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/store';
import watchIconLooker from '../../images/watch_icon_looker.svg';
import { replace } from 'redux-first-history';
import { soundFiles } from '../../components/SoundLoader/SoundLoader';

const LookerFlow: React.FC = () => {
  const { t } = useTranslation();
  const subject = useSelector(subjectSelector);
  const timeBoxedTrial = useSelector(timeBoxedTrialSelector);
  const trialDuration = useSelector(trialDurationSelector);
  const currentTrial = useSelector(currentTrialSelector);
  const lookerWatchSubject = currentTrial?.looker_watch_subject;
  const [seconds, setSeconds] = useState(trialDuration);
  const dispatch = useAppDispatch();
  const isTimeBoxedWait = timeBoxedTrial && seconds !== 0;
  const isTimeBoxedAnswer = timeBoxedTrial && seconds === 0;
  const looker = useSelector(lookerSelector);

  useEffect(() => {
    if (timeBoxedTrial) {
      let timer: NodeJS.Timeout;
      let timedSeconds = trialDuration;
      timer = setInterval(() => {
        if (timedSeconds > 0) {
          setSeconds(--timedSeconds);
        }

        if (timedSeconds <= 0) {
          clearInterval(timer);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
        setSeconds(trialDuration);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeBoxedTrial, trialDuration]);

  // On index change navigate to next trial or end session
  useEffect(() => {
    if (currentTrial) {
      if (!currentTrial.subject_can_answer) {
        //wait for looker to press next
        dispatch(replace('/subject-answered'));
      }
    }
  }, [dispatch, currentTrial]);

  useEffect(() => {
    const playSounds = async () => {
      try {
        if (
          // Opted in to play sounds
          looker?.play_sounds &&
          // And is the right time after wait time
          isTimeBoxedWait &&
          // Sound loaded
          soundFiles.countDownStarted.wasLoaded
        ) {
          //setAudioPlayedFlag(true);
          setTimeout(() => soundFiles.countDownStarted.play(), 500);
        }
      } catch (e) {
        alert('Play sound error');
      }
    };
    playSounds();
  }, [looker?.play_sounds, isTimeBoxedWait]);

  return (
    <SessionWrapper
      subjectName={
        subject?.user?.name
          ? t("{{name}}'s {{device}}", { name: subject?.user?.name, device: subject?.name })
          : t(`Subject's {{device}}`, { device: subject?.name })
      }
    >
      <IonList lines="none" color="none" className="looker-flow-wrapper">
        <div className="text-container">
          {isTimeBoxedAnswer ? (
            <>
              <h3 className="waiting-txt">{t('Waiting for answer')}</h3>
            </>
          ) : (
            <>
              {lookerWatchSubject === WATCH_SUBJECT ? (
                <IonItem color="none" className="ion-text-center ion-no-margin ion-no-padding">
                  <div>
                    <h3 className="watch-subject">{t('Look')}</h3>
                    <h3 className="at-subject">{t('at the subject')}</h3>
                  </div>
                </IonItem>
              ) : (
                <IonItem color="none" className="ion-text-center ion-no-margin ion-no-padding">
                  <div>
                    <h3 className="dont-watch-subject">{t('Don’t')}</h3>
                    <h3 className="at-subject">{t('look at the subject')}</h3>
                  </div>
                </IonItem>
              )}
            </>
          )}
        </div>
        {isTimeBoxedWait ? (
          <div className="progress-bar-container">
            <CircularProgressbarWithChildren
              strokeWidth={4}
              value={seconds}
              maxValue={trialDuration}
              styles={buildStyles({
                strokeLinecap: 'round',
              })}
              counterClockwise
            >
              <div className="seconds-wrapper">
                <span className="seconds">{seconds}</span>
                <span className="seconds-text">{t(`${seconds === 1 ? 'second' : 'seconds'}`)}</span>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        ) : (
          <>
            <IonItem color="none" className="ion-no-margin ion-no-padding icon-wrapper">
              <div className="icon-container">
                <IonImg src={watchIconLooker} alt="watch icon" className="custom-icon" />
              </div>
            </IonItem>
          </>
        )}
        <div className="under-text">
          {isTimeBoxedWait && (
            <div className="subtitle">
              {t('You can go to the next trial after the subject answers')}
            </div>
          )}
          {isTimeBoxedAnswer && (
            <>
              <div className="subtitle">{t("Don't do anything")}</div>
            </>
          )}
        </div>
        <IonItem color="none" className="ion-text-center ion-no-margin ion-no-padding next-button">
          <IonButton className="tertiary-type-button" disabled={true}>
            {t('Next')}
          </IonButton>
        </IonItem>
      </IonList>
    </SessionWrapper>
  );
};

export default LookerFlow;
