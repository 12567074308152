import {
  CheckboxChangeEventDetail,
  IonAvatar,
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonInput,
  useIonModal,
  IonList,
} from '@ionic/react';

import React, { useRef, useState } from 'react';
import LogOutSvg from '../../images/logout.svg';
import './Profile.scoped.scss';
import { ReactComponent as CameraSvg } from '../../images/camera.svg';
import { useTranslation, withTranslation } from 'react-i18next';
import { useLogOutUserMutation, useUpdateUserProfileMutation } from '../../store/api';
import { useAppSelector } from '../../store/hooks';
import {
  allowShareLocationSelector,
  locationPermissionSelector,
  setAllowShareLocation,
  setParticipant,
  userSelector,
} from '../../store/slices';
import { UserProfileRequest } from '../../models';
import { useAppDispatch } from '../../store/store';
import { push } from 'redux-first-history';
import arrow from '../../images/arrow-white.svg';
import edit from '../../images/edit.svg';
import editable from '../../images/editable.svg';
import {
  allowShareLocation,
  genderOptions,
  languageOptions,
  yearOptions,
} from '../../appConfigurations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import UpdatePasswordModal from '../../components/UpdatePasswordModal/UpdatePasswordModal';
import { IonSelectCustomEvent } from '@ionic/core/dist/types/components';
import { SelectChangeEventDetail } from '@ionic/core';
import { Controller, useForm } from 'react-hook-form';
import i18n from 'i18next';
import userDefaultAvatar from '../../images/user.svg';
import { useSelector } from 'react-redux';
import BackButton from '../../components/BackButton/BackButton';

const setNewLanguage = (language: string) => {
  i18n.changeLanguage(language).then(() => {
    localStorage.setItem('language', language);
  });
};

const Profile: React.FC = () => {
  const user = useAppSelector(userSelector);
  const { name, gender, language, year_of_birth, photo_path, email } = user || {};
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [userName, setUserName] = useState(name);
  const [userGender, setUserGender] = useState(gender);
  const [userLanguage, setUserLanguage] = useState(language);
  const [userYearOfBirth, setUserYearOfBirth] = useState(year_of_birth);
  const [userProfilePhoto, setUserProfilePhoto] = useState<File | null>(null);
  const [picture, setPicture] = useState('');
  const [logout] = useLogOutUserMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const dispatch = useAppDispatch();
  const userAvatar = photo_path?.endsWith('/storage') && !picture ? userDefaultAvatar : photo_path;
  const fileRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
  const allowShareLocationStore = useSelector(allowShareLocationSelector);
  const locationPermission = useSelector(locationPermissionSelector);

  const { control, setValue, getValues } = useForm({
    defaultValues: {
      shareLocation: allowShareLocationStore,
    },
  });

  const { t } = useTranslation();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const generalInfoEdit = () => {
    setEditInfo(true);
  };

  const editCancel = () => {
    if (fileRef) {
      fileRef.current!.value = '';
    }

    setPicture('');
    setEditInfo(false);
    setUserName(name);
    setUserGender(gender);
    setUserLanguage(language);
    setUserYearOfBirth(year_of_birth);
    setValue('shareLocation', allowShareLocationStore);
  };

  const [present, dismiss] = useIonModal(UpdatePasswordModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const openModal = () => {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
          setToastMessage(`${t('Password updated')}`);
          setShowToast(true);
        }
      },
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setErrorMessage(false);

    const shareLocation = getValues('shareLocation');
    dispatch(setAllowShareLocation(shareLocation));

    const newProfile = {
      ...user,
      gender: userGender,
      name: userName,
      language: userLanguage,
      year_of_birth: userYearOfBirth,
    };
    const profile_photo = userProfilePhoto;

    // filter the form
    try {
      // Create new FormData object
      let formData = new FormData();
      // Append userData to FormData object

      for (const [key, value] of Object.entries(newProfile)) {
        if (typeof value === 'number') {
          formData.append(key, value.toString());
        } else if (value) {
          formData.append(key, value);
        }
      }

      // Append profile_photo field to FormData object
      if (profile_photo) {
        const file = profile_photo;
        formData.append('profile_photo', file);
      }
      // Call updateUserProfile function with FormData object
      const updateUserResult = await updateUserProfile({
        userData: formData,
      } as UserProfileRequest).unwrap();

      if (updateUserResult) {
        dispatch(setParticipant(newProfile));
        setToastMessage(`${t('User updated')}`);
        setShowToast(true);
        setEditInfo(false);
      }
    } catch (e: any) {
      const errorsMessage = Object.values(e?.data?.errors).join('\n');
      setErrorMessage(true);
      setToastMessage(errorsMessage);
      setShowToast(true);
    }
  };

  const handleLogout = async () => {
    try {
      const logoutResult = await logout();

      if (logoutResult) {
        dispatch(push('/login'));
      }
    } catch (e) {
      dispatch(push('/home'));
    }
  };

  const onFileChange = async (fileList: FileList | null) => {
    const file = fileList?.item(0);
    if (file && file instanceof Blob) {
      setEditInfo(true);
      setPicture(await fileToBase64(file));
    }
  };

  const fileToBase64 = async (img: Blob) => {
    const reader = new FileReader();
    const dataUrlPromise: Promise<string> = new Promise((resolve) =>
      reader.addEventListener('load', () => resolve(reader.result as string))
    );
    reader.readAsDataURL(img);
    return await dataUrlPromise;
  };

  const goToHowAppWorks = () => {
    dispatch(push('/how-our-app-works'));
  };

  return (
    <IonPage>
      <IonContent className="edit-profile-page" forceOverscroll={false}>
        <IonGrid className="page-wrapper">
          <IonRow className="actions">
            <IonCol className="ion-align-self-center">
              <BackButton defaultPath="/home" />
            </IonCol>
            <IonCol className="ion-align-self-center">
              <IonItem
                lines="none"
                color="none"
                className="logout-button"
                onClick={() => handleLogout()}
              >
                <IonLabel className="logout-label">{t('LOG OUT')}</IonLabel>
                <IonIcon className="logout-icon" src={LogOutSvg} />
              </IonItem>
            </IonCol>
          </IonRow>
          <form onSubmit={onSubmit} className="ion-padding edit-profile-form">
            <IonRow className="profile-photo">
              <IonCol>
                <div className="avatar-container-wrap">
                  <div className="avatar-container">
                    <IonAvatar>
                      <img src={picture || userAvatar} alt="" />
                      <div className="camera-round-container">
                        <input
                          type="file"
                          ref={fileRef}
                          className="file-input"
                          onChange={(e) => {
                            setUserProfilePhoto(
                              (e.nativeEvent?.target as HTMLInputElement)?.files?.[0] || null
                            );
                            onFileChange((e.nativeEvent?.target as HTMLInputElement)?.files);
                          }}
                        ></input>
                        <CameraSvg width="20px" height="20px" />
                      </div>
                    </IonAvatar>
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="user-name">
              <IonCol>{name}</IonCol>
            </IonRow>
            <IonRow className="user-email">
              <IonCol>{email}</IonCol>
            </IonRow>
            <IonList color="none">
              <IonButton className="primary-type-button" onClick={goToHowAppWorks}>
                <div className="how-app-works-text">
                  <span>{t('How our app works')}</span>
                  <IonImg src={arrow} alt="arrow icon" className="how-app-works-arrow" />
                </div>
              </IonButton>
              <IonItem
                lines="none"
                color="none"
                className="general-info ion-no-margin ion-no-padding"
              >
                <IonLabel>{t('General Information')}</IonLabel>
                <IonIcon size="small" src={!editInfo ? edit : editable} onClick={generalInfoEdit} />
              </IonItem>
            </IonList>
            <div className="input-container">
              <IonLabel>
                {t('Your name')}
                <span className="name-field-required">*</span>
              </IonLabel>
              <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
                <IonInput
                  disabled={!editInfo}
                  type="text"
                  placeholder={t('Your Name')}
                  className="custom-input"
                  value={userName}
                  onIonChange={(e) => setUserName(e.detail.value!)}
                />
              </IonItem>
            </div>
            <div className="input-container">
              <IonLabel>{t('Your birth year')}</IonLabel>
              <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
                <IonSelect
                  interface="action-sheet"
                  className="custom-item-select"
                  placeholder={t('Please select a year')}
                  disabled={!editInfo}
                  value={userYearOfBirth}
                  onIonChange={(e: IonSelectCustomEvent<SelectChangeEventDetail>) =>
                    setUserYearOfBirth(e.detail.value)
                  }
                >
                  {yearOptions?.length &&
                    yearOptions.map((item: number, index: number) => (
                      <IonSelectOption key={index} value={item} className="select-settings-option">
                        {item}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
            </div>
            <div className="input-container">
              <IonLabel>{t('Your gender')}</IonLabel>
              <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
                <IonSelect
                  color="none"
                  interface="popover"
                  className="custom-item-select"
                  value={userGender}
                  disabled={!editInfo}
                  placeholder={t('Please select your gender')}
                  onIonChange={(e: IonSelectCustomEvent<SelectChangeEventDetail>) =>
                    setUserGender(e.detail.value)
                  }
                >
                  {genderOptions?.length &&
                    genderOptions.map((item) => (
                      <IonSelectOption
                        key={item.id}
                        value={item.option}
                        className="select-settings-option"
                      >
                        {item.text}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
            </div>
            <div className="input-container">
              <IonLabel>{t('Your language')}</IonLabel>
              <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
                <IonSelect
                  color="none"
                  interface="popover"
                  value={userLanguage}
                  disabled={!editInfo}
                  className="custom-item-select"
                  placeholder={t('Please select a language')}
                  onIonChange={(e: IonSelectCustomEvent<SelectChangeEventDetail>) => {
                    setUserLanguage(e.detail.value);
                    setNewLanguage(e.detail.value);
                  }}
                >
                  {languageOptions?.length &&
                    languageOptions.map((item) => (
                      <IonSelectOption key={item.id} value={item.option}>
                        {item.text}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
            </div>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin item-with-checkbox"
            >
              <IonLabel className="settings-label-text">{t('Allow location sharing')}</IonLabel>
              <Controller
                control={control}
                name="shareLocation"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <IonCheckbox
                    disabled={!editInfo || locationPermission === 'denied'}
                    className="ion-no-margin"
                    checked={locationPermission === 'denied' ? false : value}
                    onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                      onChange(e?.detail.checked);
                      allowShareLocation(e?.detail.checked, setValue);
                    }}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
            </IonItem>
            {editInfo && (
              <IonItem
                lines="none"
                color="none"
                className="ion-no-margin ion-no-padding fixed-button"
              >
                <div className="buttons-wrap">
                  <IonButton
                    fill="clear"
                    className="ion-margin-top primary-type-button-small"
                    onClick={editCancel}
                  >
                    {t('Cancel')}
                  </IonButton>
                  <IonButton type="submit" className="ion-margin-top primary-type-button-small">
                    {t('Save')}
                  </IonButton>
                </div>
              </IonItem>
            )}
          </form>
          <IonList className="ion-padding edit-profile-page edit-profile-form">
            <IonItem
              lines="none"
              color="none"
              className="general-info ion-no-margin ion-no-padding"
            >
              <IonLabel>{t('Password')}</IonLabel>
              <IonIcon size="small" src={edit} onClick={openModal} />
            </IonItem>
            <div className="input-container">
              <IonLabel>{t('Your Password')}</IonLabel>
              <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
                <IonInput
                  type={passwordShown ? 'text' : 'password'}
                  placeholder={t('Your password')}
                  className="custom-input"
                />
                <button className="password-visible-button" onClick={togglePassword}>
                  <FontAwesomeIcon
                    icon={passwordShown ? (faEye as any) : (faEyeSlash as any)}
                    className="show-password"
                  />
                </button>
              </IonItem>
            </div>
          </IonList>
        </IonGrid>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={errorMessage ? 'danger' : 'success'}
        />
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(Profile);
