import SessionWrapper from '../../components/SessionWrapper/SessionWrapper';
import { useAppDispatch } from '../../store/store';
import React, { useEffect } from 'react';
import {
  lookerSelector,
  participantSelector,
  resetStartListeningSessionId,
  sessionIdSelector,
  statusSelector,
  subjectSelector,
  isAuthenticatedSelector,
} from '../../store/slices';
import { replace } from 'redux-first-history';
import { useSelector } from 'react-redux';
import { soundFiles } from '../../components/SoundLoader/SoundLoader';
import { IonButton, IonItem, IonList, IonText, IonTextarea, useIonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SessionApi, useParticipantFeedbackMutation } from '../../store/api';
import { SessionIdRequest, HasFeedbackRequest } from '../../models';
import { useState } from 'react';
import {
  STATUS_LOOKER_CANCELED,
  STATUS_SUBJECT_CANCELED,
  TYPE_LOOKER,
  TYPE_SUBJECT,
} from '../../appConfigurations';
import './EndOfSession.scoped.scss';
import EndOfSessionModal from '../../components/EndOfSessionModal/EndOfSessionModal';

const EndOfSession: React.FC = () => {
  const dispatch = useAppDispatch();
  const looker = useSelector(lookerSelector);
  const subject = useSelector(subjectSelector);
  const sessionId = useSelector(sessionIdSelector);
  const [feedback, setFeedback] = useState('');
  const [participantFeedback] = useParticipantFeedbackMutation();
  const { type } = useSelector(participantSelector);
  const { id: lookerId } = useSelector(lookerSelector);
  const { id: subjectId } = useSelector(subjectSelector);
  const { t } = useTranslation();
  const status = useSelector(statusSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const { type: participantType } = useSelector(participantSelector);
  const lookerCanceled = status === STATUS_LOOKER_CANCELED && participantType === TYPE_SUBJECT;
  const subjectCanceled = status === STATUS_SUBJECT_CANCELED && participantType === TYPE_LOOKER;

  //end session modal
  const [presentModal, dismissModal] = useIonModal(EndOfSessionModal, {
    onDismiss: (data: string, role: string) => dismissModal(data, role),
  });

  const openEndSessionModal = () => {
    presentModal();
  };

  const goToResults = async () => {
    try {
      if (feedback) {
        await participantFeedback({
          participant: type === TYPE_SUBJECT ? subjectId : lookerId,
          feedback,
          sessionId,
        } as HasFeedbackRequest).unwrap();
      }

      await dispatch(
        SessionApi.endpoints.getCurrentSession.initiate(
          { sessionId: sessionId } as SessionIdRequest,
          { forceRefetch: true }
        )
      ).unwrap();

      if (isAuthenticated) {
        dispatch(
          SessionApi.endpoints.averageScore.initiate(undefined, { forceRefetch: true })
        ).unwrap();

        dispatch(
          SessionApi.endpoints.listAllSessions.initiate({ page: 1 }, { forceRefetch: true })
        ).unwrap();
      }

      dispatch(replace('/results'));
      dispatch(resetStartListeningSessionId());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      // Opted in to play sounds
      type === TYPE_LOOKER
        ? looker?.play_sounds
        : subject?.play_sounds &&
          // Sound loaded
          soundFiles.endSession.wasLoaded
    ) {
      soundFiles.endSession.play();
    }
  }, [type, looker?.play_sounds, subject?.play_sounds]);

  useEffect(() => {
    if (lookerCanceled || subjectCanceled) {
      openEndSessionModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookerCanceled, subjectCanceled]);

  return (
    <SessionWrapper
      lookerName={
        looker?.user?.name
          ? t("{{name}}'s {{device}}", { name: looker?.user?.name, device: looker?.name })
          : t("Looker's {{device}}", { device: looker?.name })
      }
    >
      <IonList
        lines="none"
        className={`end-of-session-wrapper ${
          participantType === TYPE_SUBJECT ? 'subject' : 'looker'
        }`}
      >
        <div>
          <div>
            <IonItem color="none" className="ion-no-margin ion-no-padding">
              <h6 className="made-it-to-the-end">{t('You made it to the end!')}</h6>
            </IonItem>
            <IonItem color="none" className="ion-no-margin ion-no-padding">
              <h3 className="feedback-text">{t('Give us some feedback')}</h3>
            </IonItem>
          </div>
          <div>
            <IonItem color="none" className="ion-no-margin ion-no-padding">
              <IonText className="subtitle">{t('How did you feel during the session?')}</IonText>
            </IonItem>
            <IonItem color="none" className="ion-no-margin ion-no-padding">
              <IonTextarea
                rows={6}
                placeholder={t('Please enter the answer')}
                className="how-do-you-feel-textarea"
                onIonChange={(e) => setFeedback(e.detail.value!)}
              />
            </IonItem>
          </div>
        </div>
        <IonItem color="none" className="ion-no-margin ion-no-padding">
          <div className="view-results-container">
            <IonButton className="results-button" onClick={goToResults}>
              {t('View the results')}
            </IonButton>
          </div>
        </IonItem>
      </IonList>
    </SessionWrapper>
  );
};

export default EndOfSession;
