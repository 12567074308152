import { IonItem, IonText } from '@ionic/react';

type ValidationWrapperProps = {
  children?: React.ReactNode;
  errors: any;
};

const ValidationWrapper: React.FC<ValidationWrapperProps> = ({ errors, children }) => {
  const errorMessageColor =
    errors?.type === 'required' || errors?.type === 'server' || errors?.type === 'password'
      ? 'danger'
      : 'warning';

  return (
    <>
      {children && <>{children}</>}
      {errors && (
        <IonItem color="none" lines="none" className="error-message-wrapper">
          <IonText color={errorMessageColor} className="error-message">
            {errors.message}
          </IonText>
        </IonItem>
      )}
    </>
  );
};

export default ValidationWrapper;
