import { IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './SessionIsStartingWrapper.scoped.scss';
import { TYPE_SUBJECT } from '../../appConfigurations';
import { useSelector } from 'react-redux';
import { participantSelector } from '../../store/slices';
import React from 'react';
import BackButton from '../BackButton/BackButton';
type SessionIsStartingProps = {
  children: React.ReactNode;
  openModal: Function;
};

const SessionIsStartingWrapper = ({ children, openModal }: SessionIsStartingProps) => {
  const { t } = useTranslation();
  const { type: participantType } = useSelector(participantSelector);

  return (
    <IonPage>
      <IonContent
        className={participantType === TYPE_SUBJECT ? 'subject-settings-bg' : 'looker-settings-bg'}
      >
        <IonGrid
          className={`session-is-starting page-wrapper ${
            participantType === TYPE_SUBJECT ? 'subject' : 'looker'
          }`}
        >
          <IonRow className="header">
            <div className="btn-container">
              <BackButton conditionalNavigation={(back: Function) => openModal(back)} />
            </div>
            <h1 className="page-title">
              {participantType === TYPE_SUBJECT ? t('Subject') : t('Looker')}
            </h1>
          </IonRow>

          <div className="session-is-stating-container">
            <IonRow className="ion-text-center session-is-starting-header">
              <div>{t('Session')}</div>
              <div>{t('is starting')}</div>
            </IonRow>
            {children}
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SessionIsStartingWrapper;
