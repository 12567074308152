import { Participant } from './Session';

export const AUTH_STATE_NAME = 'auth';

export interface AuthState {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  participant: Participant;
  allowShareLocation: boolean;
}

export interface PasswordRecoveryRequest {
  email: string;
}

export interface PasswordResetRequest {
  token: string;
  password: string;
  password_confirmation: string;
  email: string;
}

export interface UserPasswordUpdateRequest {
  old_password: string;
  new_password: string;
  password_confirmation: string;
}

export interface RegisterRequest {
  name: string;
  email: string;
  password: string;
  appId: string;
}

export interface LoginRequest {
  email: string;
  password: string;
  appId: string;
}

export interface LoginResponse {
  data: {
    access_token: string;
    expires_in: number;
  };
}

export interface User {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  role: string;
  year_of_birth: number;
  gender: number;
  language: string;
  photo_path: string;
  created_at: string;
  updated_at: string;
}

export interface UserProfileInfo {
  name?: string;
  year_of_birth?: string;
  gender?: number;
  language?: string;
  password?: string;
  password_confirmation?: string;
  latitude?: string;
  longitude?: string;
  profile_photo?: File;
}

export interface UserProfile {
  userData: FormData;
}

export type UserProfileRequest = UserProfile;
