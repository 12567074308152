import SessionWrapper from '../../components/SessionWrapper/SessionWrapper';
import GetReady from '../GetReady/GetReady';
import { useSelector } from 'react-redux';
import { lookerSelector } from '../../store/slices';
import { replace } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';

const GetReadySubject: React.FC = () => {
  const looker = useSelector(lookerSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <SessionWrapper
      lookerName={
        looker?.user?.name
          ? t("{{name}}'s {{device}}", { name: looker?.user?.name, device: looker?.name })
          : t("Looker's {{device}}", { device: looker?.name })
      }
    >
      <GetReady onDone={() => dispatch(replace('/subject-flow'))} />
    </SessionWrapper>
  );
};

export default GetReadySubject;
