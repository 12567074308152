import './SubjectSettings.scoped.scss';
import {
  CheckboxChangeEventDetail,
  IonButton,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToast,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  genderOptions,
  TYPE_SUBJECT,
  yearOptions,
  allowShareLocation,
  allowMicrophone,
} from '../../appConfigurations';
import { useAppDispatch } from '../../store/store';
import {
  allowShareLocationSelector,
  appSettingsSelector,
  blindfoldSelector,
  isAuthenticatedSelector,
  locationPermissionSelector,
  participantSelector,
  setBlindfold,
  setParticipant,
} from '../../store/slices';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { FieldValues, useForm, Controller } from 'react-hook-form';
import { Device } from '@capacitor/device';
import { useCreateSessionMutation } from '../../store/api';
import BackButton from '../../components/BackButton/BackButton';
import { replace } from 'redux-first-history';
import { Participant, Session } from '../../models';

const SubjectSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const participant = useSelector(participantSelector);
  const [createSession] = useCreateSessionMutation();
  const {
    numberOfTrials,
    timeBoxedTrial,
    trialDuration,
    playSounds: playSoundsStore,
    skipTrial: canSkip,
  } = useSelector(appSettingsSelector);
  const blindfoldStore = useSelector(blindfoldSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const allowShareLocationStore = useSelector(allowShareLocationSelector);
  const { register, handleSubmit, control, watch, setValue, getValues } = useForm({
    defaultValues: {
      play_sounds: playSoundsStore,
      gender: participant?.gender,
      year_of_birth: participant?.year_of_birth,
      shareLocation: isAuthenticated
        ? allowShareLocationStore
        : !!(participant?.latitude && participant?.longitude),
      latitude: participant?.latitude,
      longitude: participant?.longitude,
      blindfold: !!blindfoldStore,
    },
  });
  const shareLocation = getValues('shareLocation');
  const [isLoading, setIsLoading] = useState(false);
  const [microphoneAllowed, setMicrophoneAllowed] = useState(false);
  const [toast, setToast] = useState({
    showToast: false,
    toastMessage: '',
  });
  const locationPermission = useSelector(locationPermissionSelector);

  useEffect(() => {
    allowMicrophone(setMicrophoneAllowed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    allowShareLocation(shareLocation, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareLocation]);

  const onSubmit = async (data: FieldValues) => {
    const { play_sounds, gender, year_of_birth, latitude, blindfold, longitude } = data || {};

    try {
      setIsLoading(true);
      const info = await Device.getInfo();

      const userSettings: Participant = {
        type: TYPE_SUBJECT,
        ready: false,
        play_sounds: play_sounds,
        year_of_birth: year_of_birth || null,
        gender: gender || null,
        latitude: latitude || null,
        longitude: longitude || null,
        feedback: '',
        name: info?.model,
        group: participant?.group,
      };

      const sessionSettings: Partial<Session> & { numberOfTrials: number | string } = {
        subject_blindfold: blindfold,
        can_skip: !!canSkip,
        numberOfTrials: numberOfTrials,
        time_to_answer: timeBoxedTrial ? trialDuration : 0,
      };

      dispatch(setParticipant(userSettings));
      dispatch(setBlindfold(blindfold));

      await createSession({
        sessionSettings,
        userSettings,
      }).unwrap();

      dispatch(replace('/lets-connect-subject'));
    } catch (error) {
      setToast({
        showToast: true,
        toastMessage: 'Error generating session, please try again',
      });
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <IonPage>
      <IonContent forceOverscroll={false} className="subject-settings-bg">
        <IonList className="subject-settings-page">
          <IonLoading isOpen={isLoading} spinner={'circular'} duration={5000} />
          <div className="back-container">
            <BackButton defaultPath="/new-session" />
          </div>
          <IonItem
            lines="none"
            color="none"
            className="ion-no-padding ion-no-margin page-title-container"
          >
            <h1 className="page-title">{t('Subject')}</h1>
          </IonItem>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <IonText className="subtitle">
              {t('You will be tested how good your senses are')}
            </IonText>
          </IonItem>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label item-with-checkbox"
            >
              <IonLabel className="settings-label-text">{t('Blindfolded')}</IonLabel>
              <Controller
                control={control}
                name="blindfold"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <IonCheckbox
                    className="ion-no-margin"
                    checked={value}
                    disabled={!microphoneAllowed}
                    onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                      onChange(e?.detail.checked);
                      if (e?.detail.checked) {
                        setValue('play_sounds', true);
                      }
                    }}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-subtitle-wrapper"
            >
              <IonText className="settings-subtitle-text">
                {t('If you wear a blindfold during the session')}
              </IonText>
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label item-with-checkbox"
            >
              <IonLabel className="settings-label-text">{t('Sounds')}</IonLabel>
              <Controller
                control={control}
                name="play_sounds"
                defaultValue={playSoundsStore}
                render={({ field: { onChange, value } }) => (
                  <IonCheckbox
                    className="ion-no-margin"
                    disabled={watch('blindfold')}
                    checked={value}
                    onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                      onChange(e?.detail.checked);
                    }}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-subtitle-wrapper"
            >
              <IonText className="settings-subtitle-text">
                {t(
                  'These sounds will be played to guide you, indicating the start and the end of each trial. It is okay if you are in the same room as the looker, as it does not matter if the looker can hear you or not.'
                )}
              </IonText>
            </IonItem>
            <hr />
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label"
            >
              <IonLabel>{t('Your birth year')}</IonLabel>
            </IonItem>
            <IonItem lines="none" className="ion-no-padding ion-no-margin custom-item-select">
              <IonSelect
                interface="action-sheet"
                className="select-settings-option"
                placeholder={t('Please select a year')}
                disabled={!!(isAuthenticated && participant?.year_of_birth)}
                {...register('year_of_birth')}
              >
                {yearOptions?.length &&
                  yearOptions.map((item: number, index: number) => (
                    <IonSelectOption key={index} value={item} className="select-settings-option">
                      {item}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label"
            >
              <IonLabel>{t('Gender')}</IonLabel>
            </IonItem>
            <IonItem lines="none" className="ion-no-padding ion-no-margin custom-item-select">
              <IonSelect
                interface="popover"
                className="select-settings-option"
                placeholder={t('Please select your gender')}
                disabled={!!(isAuthenticated && participant?.gender)}
                {...register('gender')}
              >
                {genderOptions?.length &&
                  genderOptions.map((item) => (
                    <IonSelectOption
                      key={item.id}
                      value={item.option}
                      className="select-settings-option"
                    >
                      {item.text}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label item-with-checkbox allow-location"
            >
              <IonLabel className="settings-label-text">{t('Allow location sharing')}</IonLabel>
              <Controller
                control={control}
                name="shareLocation"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <IonCheckbox
                    className="ion-no-margin"
                    checked={locationPermission === 'denied' ? false : value}
                    disabled={locationPermission === 'denied'}
                    onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                      onChange(e?.detail.checked);
                      allowShareLocation(e?.detail.checked, setValue);
                    }}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-margin ion-no-padding fixed-button"
            >
              <IonButton className="secondary-type-button center-button" type="submit">
                {t('Next')}
              </IonButton>
            </IonItem>
          </form>
          <IonToast
            isOpen={toast.showToast}
            onDidDismiss={() => setToast({ showToast: false, toastMessage: '' })}
            message={toast.toastMessage}
            duration={3000}
          />
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default SubjectSettings;
