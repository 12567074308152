import { createApi } from '@reduxjs/toolkit/query/react';
import {
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  PasswordRecoveryRequest,
  PasswordResetRequest,
  User,
  UserProfileRequest,
  UserPasswordUpdateRequest,
} from '../../models';
import { baseQuery } from './baseQuery';

export const AuthApi = createApi({
  reducerPath: 'auth-api',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    loginUser: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    getUser: builder.query<User, void>({
      query: () => ({
        url: '/auth/user',
        method: 'GET',
      }),
    }),
    logOutUser: builder.mutation<{ message: string }, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
    }),
    register: builder.mutation<LoginResponse, RegisterRequest>({
      query: (registerInfo) => ({
        url: '/auth/register',
        method: 'POST',
        body: registerInfo,
      }),
    }),
    refreshToken: builder.mutation<LoginResponse, void>({
      query: () => ({
        url: '/auth/refresh',
        method: 'POST',
      }),
    }),
    recovery: builder.mutation<{ message: string }, PasswordRecoveryRequest>({
      query: (data) => ({
        url: '/auth/recovery',
        method: 'POST',
        body: data,
      }),
    }),
    reset: builder.mutation<{ message: string }, PasswordResetRequest>({
      query: (data) => ({
        url: '/auth/reset',
        method: 'POST',
        body: data,
      }),
    }),
    updateUserProfile: builder.mutation<User, UserProfileRequest>({
      query: ({ userData }) => {
        userData.append('_method', 'PUT');
        return {
          url: `/client/user`,
          method: 'POST',
          body: userData,
        };
      },
    }),
    updatePassword: builder.mutation<{ message: string }, UserPasswordUpdateRequest>({
      query: (userData) => ({
        url: `/client/user/password`,
        method: 'PUT',
        body: userData,
      }),
    }),
    uploadImage: builder.mutation<void, { formData?: object }>({
      query: ({ formData }) => ({
        url: 'client/user/update-profile-photo',
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useGetUserQuery,
  useLogOutUserMutation,
  useRefreshTokenMutation,
  useRegisterMutation,
  useRecoveryMutation,
  useResetMutation,
  useUpdateUserProfileMutation,
  useUpdatePasswordMutation,
  useUploadImageMutation,
} = AuthApi;
