import React, { useRef, useState } from 'react';
import './SessionCodeInput.scoped.scss';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { getPlatforms } from '@ionic/react';

type SessionCodeInputProps = {
  register: UseFormRegister<FieldValues>;
  message: string;
  error: string;
};

const SessionCodeInput: React.FC<SessionCodeInputProps> = ({ register, message, error }) => {
  const [focus, setFocus] = useState(false);
  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  const refs: React.MutableRefObject<HTMLInputElement | null>[] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const codeInputFields = new Array(6).fill(0).map((item, index) => {
    const { ref, ...registerAttrs } = register(`code${index}` as const, { onBlur: handleBlur });

    const maxLength = 1;
    return {
      template: (
        <React.Fragment key={index + 6}>
          <input
            key={index}
            maxLength={maxLength}
            placeholder="-"
            className="coded-input input-text codes"
            onFocus={handleFocus}
            onKeyUp={(event) =>
              handleKeyChange({
                event,
                fieldIndex: index,
                maxLength,
              })
            }
            onInput={(e) => onInput(e)}
            ref={(e) => {
              ref(e);
              refs[index].current = e;
            }}
            {...registerAttrs}
          />
          {index === 2 && (
            <span key={7} className="separator">
              -
            </span>
          )}
        </React.Fragment>
      ),
    };
  });

  const handleKeyChange = ({
    event,
    fieldIndex,
    maxLength,
  }: {
    event: React.KeyboardEvent<HTMLInputElement>;
    fieldIndex: number;
    maxLength: number;
  }) => {
    const elt = refs[fieldIndex].current;
    if (Number(fieldIndex) < 6 && elt) {
      if (event.key.length === 1) {
        elt.value = event.key;
      }

      if (elt.value !== '' && event.key !== 'Backspace') {
        event.preventDefault();
      }

      const nextSibling = refs[fieldIndex + 1]?.current;

      const previousSibling = refs[fieldIndex - 1]?.current;

      if (elt.value.length === Number(maxLength)) {
        if (nextSibling) {
          nextSibling.focus();
        } else {
          elt.blur();
        }
      }

      if (event.key === 'Backspace') {
        elt.value = '';

        if (previousSibling) {
          if (getPlatforms().includes('android')) {
            previousSibling.value = '';
          }
          previousSibling.focus();
          event.preventDefault();
        }
      }
    }
  };

  const onInput = (e: React.FormEvent<HTMLInputElement>) => {
    const el = e.target as HTMLInputElement;
    if (el?.value.length > 1) {
      el.value = el.value[0];
    }
  };

  const handleInitialSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const valueRefs = refs.filter((ref) => !!ref?.current?.value);
    if (!valueRefs.length) {
      refs[0]?.current?.focus();
    } else {
      // Select the next empty input
      // Find the next index of the last filled element
      const index = refs.indexOf(valueRefs[valueRefs.length - 1]) + 1;
      // Focus the next field if it's not the last, otherwise focus the last element
      refs[Math.min(index, refs.length - 1)]?.current?.focus();
    }
  };

  return (
    <div
      className={`inputs-wrapper custom-input input-intercept-click ${
        message ? 'success-code' : error ? 'error-code' : focus ? 'focused' : ''
      }`}
      onClick={handleInitialSelection}
    >
      {codeInputFields.map((el) => el.template)}
    </div>
  );
};

export default SessionCodeInput;
