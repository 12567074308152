import { IonButton, IonImg, IonList } from '@ionic/react';
import closeIcon from '../../images/close-modal.svg';
import './WarningModalWrapper.scoped.scss';

const WarningModalWrapper: React.FC<{
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  children: React.ReactNode;
  closeButton: boolean;
  warningModal: boolean;
}> = ({ onDismiss, children, closeButton, warningModal }) => {
  return (
    <div className="modal-wrapper">
      <IonList className={`modal-items ${warningModal && 'warning-modal'}`}>
        {closeButton && (
          <IonButton
            fill="clear"
            className="close-modal-button"
            onClick={() => onDismiss(null, 'cancel')}
          >
            <IonImg src={closeIcon} alt="close modal icon" />
          </IonButton>
        )}
        {children}
      </IonList>
    </div>
  );
};

export default WarningModalWrapper;
