import { createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { AuthApi } from '../api';
import { setParticipant } from '../slices';

export const loadUser = createAsyncThunk<void, void>('auth/getUser', async (_, { dispatch }) => {
  batch(async () => {
    try {
      const currentUser = await dispatch(
        AuthApi.endpoints.getUser.initiate(undefined, { forceRefetch: true })
      ).unwrap();
      dispatch(setParticipant(currentUser));
    } catch (error) {
      console.log(error);
    }
  });
});
