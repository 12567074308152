import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInput,
} from '@ionic/react';
import React from 'react';
import './PasswordRecovery.scoped.scss';
import { useTranslation } from 'react-i18next';
import { useRecoveryMutation } from '../../store/api';
import { useAppDispatch } from '../../store/store';
import { push } from 'redux-first-history';
import BackButton from '../../components/BackButton/BackButton';
import { FieldValues, useForm } from 'react-hook-form';
import ValidationWrapper from '../../components/ErrorMessage/ValidationWrapper';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../store/slices';

const PasswordRecovery: React.FC = () => {
  const { t } = useTranslation();
  const [recovery] = useRecoveryMutation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const onSubmit = async (data: FieldValues) => {
    const { email } = data || {};

    try {
      const recoveryResult = await recovery({
        email: email,
      }).unwrap();

      if (recoveryResult) {
        dispatch(push('/login'));
      }
    } catch (error: any) {
      if (error.status === 422) {
        setError('email', {
          type: 'server',
          message: t(error?.data?.errors?.email),
        });
      }
    }
  };

  const emailError =
    errors?.email?.type === 'required' || errors?.email?.type === 'server' || errors.server
      ? 'error-auth'
      : errors?.email?.type === 'pattern'
      ? 'warning-auth'
      : '';

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <IonGrid className="page-wrapper ion-no-padding">
          <BackButton defaultPath={isAuthenticated ? '/my-profile' : '/login'} />
          <div className="password-recovery-wrapper">
            <IonRow>
              <IonCol className="ion-no-margin ion-no-padding">
                <h3>{t('Recovery password')}</h3>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="under-register-login-text ion-no-margin ion-no-padding">
                <IonText className="subtitle-auth">{t('Forgot your password?')}</IonText>
                <IonText className="subtitle-auth">
                  {t('Please enter your email to recover it!')}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="recovery-wrapper">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <ValidationWrapper errors={errors.email}>
                  <IonInput
                    type="text"
                    placeholder={t('Your email address')}
                    className={`custom-input ${emailError}`}
                    onIonChange={() => {
                      clearErrors('email');
                    }}
                    {...register('email', {
                      required: 'The email field is required',
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t('Please enter a valid email address'),
                      },
                    })}
                  />
                </ValidationWrapper>
                <IonRow class="ion-padding-top">
                  <IonButton className="primary-type-button" type="submit">
                    {t('Recover')}
                  </IonButton>
                </IonRow>
              </form>
            </IonRow>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PasswordRecovery;
