import ConnectionSuccessfulWrapper from '../../components/ConnectionSuccessfulWrapper/ConnectionSuccessfulWrapper';
import { IonButton, IonCol, IonRow, useIonModal } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { lookerSelector, resetAll, sessionIdSelector, subjectSelector } from '../../store/slices';
import { useLookerCancelMutation, useParticipantReadyMutation } from '../../store/api';
import { ParticipantIdSessionIdRequest, SessionIdRequest } from '../../models';
import { replace } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import './ConnectionSuccessfulLooker.scoped.scss';
import WarningModal from '../../components/WarningModal/WarningModal';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { TYPE_LOOKER } from '../../appConfigurations';

const ConnectionSuccessfulLooker: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sessionId = useSelector(sessionIdSelector);
  const subject = useSelector(subjectSelector);
  const looker = useSelector(lookerSelector);
  const [participantReady] = useParticipantReadyMutation();
  const [lookerCancel] = useLookerCancelMutation();

  const [present, dismiss] = useIonModal(WarningModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const openModal = (back: Function) => {
    present({
      onWillDismiss: async (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'accept') {
          const response = await lookerCancel({
            sessionId: sessionId,
          } as SessionIdRequest).unwrap();
          if (response) {
            dispatch(resetAll());
            back();
          }
        }
      },
    });
  };

  const lookerReady = async () => {
    try {
      await participantReady({
        participantId: looker?.id,
        sessionId: sessionId,
      } as ParticipantIdSessionIdRequest);

      if (!subject?.ready) {
        dispatch(replace('/looker-ready'));
      } else {
        dispatch(replace('/get-ready-looker'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ConnectionSuccessfulWrapper openModal={openModal} participantType={TYPE_LOOKER}>
      <div className="connection-successful-looker">
        <IonRow className="who-is-connected ">
          <IonCol className="who-is-connected-wrapper">
            <div className="who-is-connected subject-name">
              <div className="role">{t('Subject is connected')}</div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="subtitle ion-text-center ion-no-padding ion-no-margin">
          {t('Please turn your back on Looker, relax and start the test.')}
        </IonRow>
        <IonRow className="start-session-button ion-no-margin ion-no-padding">
          <IonButton className="tertiary-type-button" onClick={lookerReady}>
            {t('Start Session')}
          </IonButton>
        </IonRow>
      </div>
    </ConnectionSuccessfulWrapper>
  );
};

export default ConnectionSuccessfulLooker;
