import { useSelector } from 'react-redux';
import { setTooltip, tooltipDataSelector } from '../../store/slices';
import { useTranslation } from 'react-i18next';
import './ChartTooltip.scoped.scss';
import { useEffect } from 'react';
import { useAppDispatch } from '../../store/store';

const ChartTooltip: React.FC = () => {
  const { t } = useTranslation();
  const { selectedSession, leftPosition, percentage } = useSelector(tooltipDataSelector) || {};
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTooltip(null));
  }, [dispatch]);

  return (
    <>
      {selectedSession && <span className="chart-title">{t(`Session ${selectedSession}`)}</span>}
      {percentage && (
        <span className="session-chart-tooltip" style={{ left: `calc(${leftPosition}px + 50px)` }}>
          {percentage}
        </span>
      )}
    </>
  );
};

export default ChartTooltip;
