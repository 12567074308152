import {
  IonContent,
  IonPage,
  IonImg,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './NewSessionGuest.scoped.scss';
import { push } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import BackButton from '../../components/BackButton/BackButton';
import { useSelector } from 'react-redux';
import { homepageSelector } from '../../store/slices';
import landingPageLogo from '../../images/staring-logo.svg';

const NewSessionGuest: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const homepage = useSelector(homepageSelector);

  const startSession = () => {
    dispatch(push('/new-session'));
  };

  return (
    <IonPage>
      <IonContent className="new-session-content" forceOverscroll={false}>
        <IonGrid className="content grid-container">
          <div className="btn-container">
            <BackButton defaultPath={homepage} />
          </div>
          <div className="main-wrapper">
            <div className="main-content">
              <IonRow>
                <IonCol>
                  <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
                    <div className="logo-container">
                      <IonImg src={landingPageLogo} alt="landing page logo" />
                    </div>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h4 className="page-title">
                    {t('Great! We hope you enjoy testing your skills')}
                  </h4>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <h5 className="subtitle">{t('IMPORTANT NOTE')}</h5>
                  <div className="info">
                    {t(
                      'Your session’s data will not be logged. If you enjoyed that, we kindly ask that you contribute to our research by signing up! We hope you can improve your score!'
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </div>
            <IonRow className="row-continue">
              <IonCol>
                <IonItem
                  lines="none"
                  color="none"
                  className="ion-no-padding ion-no-margin button-container"
                >
                  <IonButton class="primary-type-button" onClick={startSession}>
                    {t('CONTINUE AS GUEST')}
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default NewSessionGuest;
