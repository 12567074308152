import { IonButton, IonIcon, IonItem } from '@ionic/react';
import close from '../../images/close.svg';
import React from 'react';

const CloseButton: React.FC<{ onClose: Function }> = ({ onClose }) => (
  <IonItem color="none" className="ion-no-margin ion-no-padding close-button">
    <IonButton className="small-settings-button-light" onClick={() => onClose()}>
      <IonIcon src={close} />
    </IonButton>
  </IonItem>
);

export default CloseButton;
