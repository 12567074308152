import SessionWrapper from '../../components/SessionWrapper/SessionWrapper';
import { IonButton, IonImg, IonItem, IonList } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './SubjectFlow.scoped.scss';
import microphoneIcon from '../../images/microphone.svg';
import {
  currentIndexSelector,
  lookerSelector,
  currentTrialSelector,
  sessionIdSelector,
  setCanAnswer,
  timeBoxedTrialSelector,
  trialDurationSelector,
  subjectSelector,
} from '../../store/slices';
import { useSelector } from 'react-redux';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import React, { useEffect, useState } from 'react';
import SpeechRecognitionComponent from '../../components/SpeechRecognitionComponent/SpeechRecognitionComponent';
import { SUBJECT_ANSWER_NO, SUBJECT_ANSWER_YES } from '../../appConfigurations';
import { SessionIdRequest, SetUserAnswerRequest } from '../../models';
import { SessionApi, useSetUserResponseMutation } from '../../store/api';
import { replace } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import { soundFiles } from '../../components/SoundLoader/SoundLoader';

const SubjectFlow: React.FC = () => {
  const [subjectResponseLoading, setSubjectResponseLoading] = useState(false);
  // This was causing issues with timing of recording after sounds are played
  const [audioPlayedFlag, setAudioPlayedFlag] = useState(false);
  const { t } = useTranslation();
  const timeBoxedTrial = useSelector(timeBoxedTrialSelector);
  const trialDuration = useSelector(trialDurationSelector);
  const [seconds, setSeconds] = useState(trialDuration);
  const currentIndex = useSelector(currentIndexSelector);
  const sessionId = useSelector(sessionIdSelector);
  const [setUserResponse] = useSetUserResponseMutation();
  const looker = useSelector(lookerSelector);
  const subject = useSelector(subjectSelector);
  const currentTrial = useSelector(currentTrialSelector);
  const dispatch = useAppDispatch();
  const isTimeBoxedWait = timeBoxedTrial && seconds !== 0;
  const isTimeBoxedAnswer = timeBoxedTrial && seconds === 0;
  const audioPlayed = !subject?.play_sounds || audioPlayedFlag;

  useEffect(() => {
    if (timeBoxedTrial) {
      let timer: NodeJS.Timeout;
      let timedSeconds = trialDuration;
      timer = setInterval(() => {
        if (timedSeconds > 0) {
          setSeconds(--timedSeconds);
        }

        if (timedSeconds <= 0) {
          clearInterval(timer);
          dispatch(setCanAnswer(true));
        }
      }, 1000);
      return () => {
        clearInterval(timer);
        setSeconds(trialDuration);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeBoxedTrial, trialDuration]);

  useEffect(() => {
    dispatch(setCanAnswer(!timeBoxedTrial));
    return () => {
      dispatch(setCanAnswer(false));
    };
  }, [dispatch, timeBoxedTrial]);

  const setSubjectResponse = async (subjectAnswer: number) => {
    if (!subjectResponseLoading) {
      setSubjectResponseLoading(true);
      try {
        await setUserResponse({
          sessionId: sessionId,
          currentIndex: currentIndex,
          subject_answer: subjectAnswer,
        } as SetUserAnswerRequest);

        await dispatch(
          SessionApi.endpoints.getTrials.initiate({ sessionId } as SessionIdRequest, {
            forceRefetch: true,
          })
        );
      } catch (error) {
        console.log(error);
        setSubjectResponseLoading(false);
      }
    }
  };

  // On index change navigate to next trial or end session
  useEffect(() => {
    if (currentTrial) {
      if (!currentTrial.subject_can_answer) {
        //wait for looker to press next, also delay for microphone to unhook itself before playing sounds
        setAudioPlayedFlag(false);
        dispatch(replace('/wait-for-next-trial'));
      }
    } else {
      //end of session, also delay for microphone to unhook itself before playing sounds
      setAudioPlayedFlag(false);
      dispatch(replace('/end-of-session'));
    }
  }, [dispatch, currentTrial, subject?.play_sounds]);

  useEffect(() => {
    const playSounds = async () => {
      try {
        if (
          // Opted in to play sounds
          subject?.play_sounds &&
          // And is the right time after wait time
          !isTimeBoxedWait &&
          // Sound loaded
          soundFiles.startTrial.wasLoaded
        ) {
          setAudioPlayedFlag(true);
          setTimeout(() => soundFiles.startTrial.play(), 500);
        }

        if (subject?.play_sounds && isTimeBoxedWait && soundFiles.countDownStarted.wasLoaded) {
          setTimeout(() => soundFiles.countDownStarted.play(), 500);
        }
      } catch (e) {
        alert('Play sound error');
      } /* finally {
        setTimeout(() => setAudioPlayedFlag(true), 1000);
      }*/
    };
    playSounds();
  }, [subject?.play_sounds, isTimeBoxedWait]);

  return (
    <SessionWrapper
      lookerName={
        looker?.user?.name
          ? t("{{name}}'s {{device}}", { name: looker?.user?.name, device: looker?.name })
          : t("Looker's {{device}}", { device: looker?.name })
      }
    >
      <IonList lines="none" color="none" className="subject-flow-wrapper">
        <div className="text-container">
          {isTimeBoxedAnswer ? (
            <>
              <IonItem color="none" className="ion-text-center ion-no-margin ion-no-padding">
                <h3 className="answer-now">{t('Answer now!')}</h3>
              </IonItem>
            </>
          ) : (
            <IonItem color="none" className="ion-text-center ion-no-margin ion-no-padding ">
              <h3 className="question">{t('Is anyone watching you now?')}</h3>
            </IonItem>
          )}
        </div>
        <div className="counter-container">
          {isTimeBoxedWait ? (
            <div className="progress-bar-container">
              <CircularProgressbarWithChildren
                strokeWidth={4}
                value={seconds}
                maxValue={trialDuration}
                styles={buildStyles({
                  strokeLinecap: 'round',
                })}
                counterClockwise
              >
                <div className="seconds-wrapper">
                  <span className="seconds">{seconds}</span>
                  <span className="seconds-text">
                    {t(`${seconds === 1 ? 'second' : 'seconds'}`)}
                    <div>{t('until you can')}</div>
                    <div>{t('answer')}</div>
                  </span>
                </div>
              </CircularProgressbarWithChildren>
            </div>
          ) : (
            <>
              <IonItem
                color="none"
                className="ion-no-margin ion-no-padding icon-wrapper"
                style={{ visibility: audioPlayed ? 'visible' : 'hidden' }}
              >
                <div className="icon-container">
                  <IonImg src={microphoneIcon} alt="microphone icon" className="custom-icon" />
                </div>
              </IonItem>
              {audioPlayed && (
                <SpeechRecognitionComponent setSubjectResponse={setSubjectResponse} />
              )}
            </>
          )}
        </div>
        <div className="action-buttons">
          <IonButton
            className="primary-type-button-small"
            disabled={isTimeBoxedWait || subjectResponseLoading}
            onClick={async () => {
              await setSubjectResponse(SUBJECT_ANSWER_NO);
            }}
          >
            {t('No')}
          </IonButton>
          <IonButton
            className="secondary-type-button-small"
            disabled={isTimeBoxedWait || subjectResponseLoading}
            onClick={async () => {
              await setSubjectResponse(SUBJECT_ANSWER_YES);
            }}
          >
            {t('Yes')}
          </IonButton>
        </div>
      </IonList>
    </SessionWrapper>
  );
};

export default SubjectFlow;
