import { useTranslation } from 'react-i18next';
import stepThree from '../../src/images/step_3.svg';
import stepFour from '../../src/images/step_4.svg';
import stepFive from '../../src/images/step_5.svg';

export function useCreateSliderConfiguration() {
  const { t } = useTranslation();

  return [
    {
      title: t('About'),
      subtitle: t(
        'A research app conceived by Dr. Alex Gomez-Marin and Dr. Rupert Sheldrake, developed by Synergo Group, designed by Jason McDonald and stewarded by Stephanie Tess Manrique. For questions or comments, please contact sheldrake@sheldrake.org'
      ),
    },
    {
      title: t('Let it be fun'),
      subtitle: t(
        'This is a fun way to test and improve your capacity to sense the looks of others when you can’t see them.'
      ),
    },
    {
      title: t('You need to work in pairs'),
      subtitle: t(
        'One of you will be the “subject” and the other will be the “looker” (you can swap roles later).'
      ),
    },
    {
      title: t('If you are the Looker'),
      subtitle: t(
        'The looker’s role is to either stare at the subject for a few seconds, or close your eyes and think about something else.'
      ),
      image: stepThree,
      imageAlt: t('image for tutorial step 3'),
    },
    {
      title: t('If you are the Looker'),
      subtitle: t(
        'The app will tell you what to do in each of the 20 trials of the experiment, which will take place consecutively.'
      ),
      image: stepFour,
      imageAlt: t('image for tutorial step 4'),
    },
    {
      title: t('If you are the Subject'),
      subtitle: t(
        'If you are the subject, you simply have to feel whether the looker is looking at you or not, and let us know after every trial.'
      ),
      image: stepFive,
      imageAlt: t('image for tutorial step 5'),
    },
    {
      title: t('If you are the Subject'),
      subtitle: t(
        'You can report whether you felt being looked at or not after the sound by pressing the screen or saying “yes” or “no”.'
      ),
      image: stepFive,
      imageAlt: t('image for tutorial step 6'),
    },
    {
      title: t('If you are the Subject'),
      subtitle: t(
        'It does not matter if the looker hears your answer, and saying “yes” or “no” makes it easier to do the test wearing a blindfold.'
      ),
      image: stepFive,
      imageAlt: t('image for tutorial step 6'),
    },
    {
      title: t('Start with some basic info'),
      subtitle: t('Before starting you need to fill in some basic information of yours.'),
    },
    {
      title: t('Link your phones'),
      subtitle: t(
        'To start with, you link your phone to your partner’s via a QR code. Then you are ready to go.'
      ),
    },
    {
      title: t('Your answers help us, a lot'),
      subtitle:
        'At the end of the 20 trials, we’ll tell you how you did and ask you how you felt (any information you wish to share will be very useful to us).',
    },
    {
      title: t("That's it"),
      subtitle: t(
        'In sum, simply follow the easy instructions, enjoy, and explore your sensing capacities!'
      ),
    },
  ];
}
