import React, { useEffect, useState } from 'react';
import SessionWrapper from '../../components/SessionWrapper/SessionWrapper';
import { lookerSelector, sessionIdSelector, subjectSelector } from '../../store/slices';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon, IonItem, IonList } from '@ionic/react';
import './SubjectAnswered.scoped.scss';
import checkmark from '../../images/checkmark.svg';
import { SessionApi, useGoToNextTrialMutation } from '../../store/api';
import { SessionIdRequest } from '../../models';
import { replace } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import { soundFiles } from '../../components/SoundLoader/SoundLoader';

const SubjectAnswered: React.FC = () => {
  const [goToNextTrialLoading, setGoToNextTrialLoading] = useState(false);
  const { t } = useTranslation();
  const subject = useSelector(subjectSelector);
  const looker = useSelector(lookerSelector);
  const sessionId = useSelector(sessionIdSelector);
  const [goToNextTrial] = useGoToNextTrialMutation();
  const dispatch = useAppDispatch();

  const handleGoToNextTrial = async () => {
    if (!goToNextTrialLoading) {
      setGoToNextTrialLoading(true);
      try {
        await goToNextTrial({ sessionId } as SessionIdRequest).unwrap();
        await dispatch(
          SessionApi.endpoints.getTrials.initiate({ sessionId } as SessionIdRequest, {
            forceRefetch: true,
          })
        );
        dispatch(replace('/looker-flow'));
      } catch (error) {
        console.log(error);
        setGoToNextTrialLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      // Opted in to play sounds
      looker?.play_sounds &&
      // Sound loaded
      soundFiles.startTrial.wasLoaded
    ) {
      soundFiles.startTrial.play();
    }
  }, [looker?.play_sounds]);

  return (
    <SessionWrapper
      subjectName={
        subject?.user?.name
          ? t("{{name}}'s {{device}}", { name: subject?.user?.name, device: subject?.name })
          : t(`Subject's {{device}}`, { device: subject?.name })
      }
    >
      <IonList lines="none" className="subject-answered-wrapper">
        <div className="text-container">
          <IonItem
            color="none"
            className="ion-text-center ion-no-margin ion-no-padding answer-now-wrapper"
          >
            <h3>{t('Subject has answered')}</h3>
          </IonItem>
        </div>
        <IonItem color="none" className="ion-no-margin ion-no-padding icon-wrapper">
          <div className="icon-container">
            <IonIcon src={checkmark} className="custom-icon" />
          </div>
        </IonItem>
        <div className="under-text">
          <div className="subtitle">{t('Go go go!')}</div>
        </div>
        <IonItem color="none" className="ion-text-center ion-no-margin ion-no-padding next-button">
          <IonButton
            className="tertiary-type-button"
            onClick={handleGoToNextTrial}
            disabled={goToNextTrialLoading}
          >
            {t('Next')}
          </IonButton>
        </IonItem>
      </IonList>
    </SessionWrapper>
  );
};

export default SubjectAnswered;
