import { createSelector, createSlice } from '@reduxjs/toolkit';
import { SESSION_NAME, SessionState, Trial } from '../../models';
import { AuthApi, SessionApi } from '../api';

const ParticipantInitialState = {
  id: null,
  user_id: null,
  type: null,
  ready: false,
  play_sounds: false,
  year_of_birth: null,
  gender: null,
  latitude: null,
  longitude: null,
  feedback: null,
  group: null,
  name: null,
};

const GeneralStatsInitialState = {
  totalNumberOfCorrectAnswers: 0,
  totalNumberOfTrials: 0,
  cumulativeAverage: 0,
};

const sessionInitialState = {
  numberOfTrials: 20,
  timeBoxedTrial: true,
  trialDuration: 8,
  skipTrial: false,
  language: 'en-US',
  playSounds: true,
  completeSessions: [],
  allSessions: [],
  sessionId: null,
  blindfold: false,
  subject_looker_relationship: null,
  looker: ParticipantInitialState,
  subject: ParticipantInitialState,
  trials: [],
  code: '',
  status: 0,
  canAnswer: false,
  answered_correctly: 0,
  total_no_of_trials_answered: 0,
  answered_percentage: 0,
  contactEmail: null,
  averageScore: {
    average: 0,
    total: 0,
  },
  generalStats: GeneralStatsInitialState,
  tooltipData: {
    leftPosition: null,
    selectedSession: null,
    percentage: null,
  },
} as SessionState;

const resetAfterLogout = () => sessionInitialState;

export const SessionSlice = createSlice({
  name: SESSION_NAME,
  initialState: sessionInitialState,
  reducers: {
    setNumberOfTrials: (state, { payload }: { payload: 10 | 20 | 40 }) => {
      state.numberOfTrials = payload;
    },
    setTimeBoxedTrial: (state, { payload }: { payload: boolean }) => {
      state.timeBoxedTrial = payload;
    },
    setTrialDuration: (state, { payload }: { payload: number }) => {
      state.trialDuration = payload;
    },
    setSkipTrial: (state, { payload }: { payload: boolean }) => {
      state.skipTrial = payload;
    },
    setLanguage: (state, { payload }: { payload: string }) => {
      state.language = payload;
    },
    setSessionId: (state, { payload }: { payload: number }) => {
      state.sessionId = payload;
    },
    setBlindfold: (state, { payload }: { payload: boolean }) => {
      state.blindfold = payload;
      state.playSounds = payload;
    },
    setPlaySounds: (state, { payload }: { payload: boolean }) => {
      state.playSounds = payload;
    },
    setSubjectLookerRelationShip: (state, { payload }: { payload: number | null }) => {
      state.subject_looker_relationship = payload;
    },
    setSubject: (state, { payload }) => {
      state.subject = payload;
    },
    setCanAnswer: (state, { payload }) => {
      state.canAnswer = payload;
    },
    resetAll: (state) => {
      state.sessionId = null;
      state.looker = ParticipantInitialState;
      state.subject = ParticipantInitialState;
      state.trials = [];
      state.code = '';
      state.status = 0;
      state.canAnswer = false;
    },
    setAllSessions: (state, { payload }) => {
      state.allSessions = [...payload, ...state.allSessions!];
    },
    setCompleteSessions: (state, { payload }) => {
      state.completeSessions = [...payload, ...state.completeSessions!];
    },
    resetStartListeningSessionId: (state) => {
      state.sessionId = null;
    },
    setTooltip: (state, { payload }) => {
      state.tooltipData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(SessionApi.endpoints.createSession.matchFulfilled, (state, { payload }) => {
      state.sessionId = payload?.id;
      state.code = payload?.code;
      state.status = payload?.status;
      state.subject = payload?.subject;
      state.trials = payload?.trials;
      state.contactEmail = payload?.contact_email;
    });
    builder.addMatcher(
      SessionApi.endpoints.getCurrentSession.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          state.looker = payload.looker;
          state.subject = payload.subject;
          state.status = payload.status;
          state.timeBoxedTrial = !!payload.time_to_answer;
          state.trialDuration =
            payload.time_to_answer !== 0 ? payload.time_to_answer : state.trialDuration;
          state.skipTrial = payload.can_skip;
          state.trials = payload.trials;
          state.blindfold = payload.subject_blindfold;
          state.subject_looker_relationship = payload.subject_looker_relationship;
          state.code = payload.code;
          state.status = payload.status;
          state.answered_correctly = payload.answered_correctly;
          state.total_no_of_trials_answered = payload.total_no_of_trials_answered;
          state.answered_percentage = payload.answered_percentage;
          state.trials = payload.trials;

          if (payload.trials) {
            state.numberOfTrials = payload.trials.length;
          }

          if (payload?.subject) {
            state.playSounds = payload.subject.play_sounds!;
          }
        }
      }
    );
    builder.addMatcher(SessionApi.endpoints.getTrials.matchFulfilled, (state, { payload }) => {
      state.trials = payload;
    });
    builder.addMatcher(
      SessionApi.endpoints.addLookerToSession.matchFulfilled,
      (state, { payload }) => {
        state.looker = payload?.looker;
        state.subject = payload?.subject;
        state.sessionId = payload?.id;
        state.status = payload?.status;
        state.trials = payload?.trials;
        state.numberOfTrials = payload?.trials.length;
      }
    );
    builder.addMatcher(
      SessionApi.endpoints.listCompleteSessions.matchFulfilled,
      (state, { payload }) => {
        state.completeSessions =
          payload.current_page === 1
            ? payload.data
            : [...(state.completeSessions || []), ...payload.data];
      }
    );
    builder.addMatcher(
      SessionApi.endpoints.listAllSessions.matchFulfilled,
      (state, { payload }) => {
        state.allSessions =
          payload.current_page === 1
            ? payload.data
            : [...(state.allSessions || []), ...payload.data];
      }
    );
    builder.addMatcher(
      SessionApi.endpoints.participantReady.matchFulfilled,
      (state, { payload }) => {
        state.status = payload?.status;
        state.looker = payload?.looker;
        state.subject = payload?.subject;
      }
    );
    builder.addMatcher(
      SessionApi.endpoints.setUserResponse.matchFulfilled,
      (state, { payload }) => {
        state.trials = payload?.trials;
      }
    );
    builder.addMatcher(SessionApi.endpoints.subjectCancel.matchFulfilled, (state, { payload }) => {
      state.trials = payload.trials;
      state.status = payload.status;
    });
    builder.addMatcher(SessionApi.endpoints.lookerCancel.matchFulfilled, (state, { payload }) => {
      state.trials = payload.trials;
      state.status = payload.status;
    });
    builder.addMatcher(SessionApi.endpoints.generalStats.matchFulfilled, (state, { payload }) => {
      state.generalStats = payload;
    });
    builder.addMatcher(SessionApi.endpoints.averageScore.matchFulfilled, (state, { payload }) => {
      state.averageScore = payload;
    });
    builder.addMatcher(AuthApi.endpoints.logOutUser.matchFulfilled, resetAfterLogout);
  },
});

type PartialState = {
  [SessionSlice.name]: SessionState;
  [key: string]: any;
};

const getCurrentTrial = (trials: Trial[]) => {
  const nextTrial = trials.find((trial) => trial.subject_answer === null);
  return nextTrial;
};

export const numberOfTrialsSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].numberOfTrials,
  (it) => it
);
export const blindfoldSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].blindfold,
  (it) => it
);
export const timeBoxedTrialSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].timeBoxedTrial,
  (it) => it
);
export const trialDurationSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].trialDuration,
  (it) => it
);
export const skipTrialSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].skipTrial,
  (it) => it
);
export const languageSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].language,
  (it) => it
);
export const playSoundsSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].playSounds,
  (it) => it
);
export const sessionIdSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].sessionId,
  (it) => it
);
export const sessionCodeSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].code,
  (it) => it
);
export const lookerSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].looker,
  (it) => it
);
export const subjectSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].subject,
  (it) => it
);
export const subjectLookerRelationshipSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].subject_looker_relationship,
  (it) => it
);
export const currentIndexSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].trials,
  (trials) => {
    const nextIndex = getCurrentTrial(trials)?.index;
    return typeof nextIndex !== 'undefined' ? nextIndex : -1;
  }
);
export const currentTrialSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].trials,
  getCurrentTrial
);
export const canAnswerSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].canAnswer,
  (it) => it
);
export const lookerWatchSubjectSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].trials,
  (trials) => {
    return getCurrentTrial(trials)?.looker_watch_subject;
  }
);
export const trialsSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].trials,
  (it) => it
);
export const answeredCorrectlySelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].answered_correctly,
  (it) => it
);
export const totalNoOfTrialsAnsweredSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].total_no_of_trials_answered,
  (it) => it
);
export const answeredPercentageSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].answered_percentage,
  (it) => it
);
export const contactEmailSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].contactEmail,
  (it) => it
);
export const statusSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].status,
  (it) => it
);
export const startListeningSessionIdSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].sessionId,
  (sessionId) => sessionId
);
export const completeSessionsSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].completeSessions,
  (it) => it
);
export const allSessionsSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].allSessions,
  (it) => it
);
export const generalStatsSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].generalStats,
  (it) => it
);
export const tooltipDataSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].tooltipData,
  (it) => it
);
export const averageScoreSelector = createSelector(
  (state: PartialState) => state[SessionSlice.name].averageScore,
  (averageScore) => averageScore
);

export const {
  setNumberOfTrials,
  setTimeBoxedTrial,
  setTrialDuration,
  setSkipTrial,
  setLanguage,
  setSessionId,
  resetAll,
  setBlindfold,
  setPlaySounds,
  setSubject,
  setSubjectLookerRelationShip,
  setCanAnswer,
  resetStartListeningSessionId,
  setAllSessions,
  setCompleteSessions,
  setTooltip,
} = SessionSlice.actions;
