import React from 'react';
import { IonButton, IonContent, IonImg, IonItem, IonList, IonPage, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './SessionResults.scoped.scss';
import { useAppDispatch } from '../../store/store';
import {
  answeredCorrectlySelector,
  answeredPercentageSelector,
  contactEmailSelector,
  resetAll,
  subjectSelector,
  totalNoOfTrialsAnsweredSelector,
  trialsSelector,
  isAuthenticatedSelector,
  averageScoreSelector,
} from '../../store/slices';
import { goBack } from 'redux-first-history';
import Answers from '../../components/Answers/Answers';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { MINIMUM_CONTACT_US_PERCENTAGE, MINIMUM_NUMBER_OF_SESSIONS } from '../../appConfigurations';
import { TrialExtraKeys } from '../../models';
import CloseButton from '../../components/CloseButton/CloseButton';
import PercentageComponent from '../../components/PercentageComponent/PercentageComponent';
import GeneralStats from '../../components/GeneralStats/GeneralStats';
import shareIcon from '../../images/share-icon.svg';

const SessionResults: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let trials = useSelector(trialsSelector) as TrialExtraKeys[];
  const subject = useSelector(subjectSelector);
  const answeredCorrectly = useSelector(answeredCorrectlySelector);
  const totalNoOfTrialsAnswered = useSelector(totalNoOfTrialsAnsweredSelector);
  const answeredPercentage = useSelector(answeredPercentageSelector);
  let { updated_at: finishedDate } = trials[trials.length - 1] || '';
  finishedDate = moment(finishedDate).format('llll');
  const contactEmail = useSelector(contactEmailSelector) || '';
  const contactUsEmail = `mailto:${encodeURIComponent(contactEmail)}?subject=${encodeURIComponent(
    t('My Staring App results are over 65%')
  )}`;
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const averageScore = useSelector(averageScoreSelector) || {};

  const onClose = () => {
    dispatch(resetAll());
    dispatch(goBack());
  };

  const onShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: t('The Staring Detection Test'),
          text: t(
            `The Staring Detection Test. Check this out, my results were ${
              Math.round(answeredPercentage * 1000) / 10
            }%!`
          ),
          url: window.location.origin,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  };

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <IonList lines="none" className="page-wrapper results-page-wrapper">
          <div className="first-container">
            <div className="close-or-share">
              <CloseButton onClose={onClose} />
            </div>
            <div color="none" className="ion-no-margin ion-no-padding head">
              <h1 className="results-title">{t('Results')}</h1>
              <div className="results-date-container">
                <span className="results-date">{finishedDate}</span>
                <IonButton fill="clear" className="share-button" onClick={onShare}>
                  {t('Share')}
                  <IonImg className="share-icon" src={shareIcon} alt="share icon" />
                </IonButton>
              </div>
            </div>
            <div className="results-circle">
              <PercentageComponent
                mode="session-results"
                name={subject?.name}
                answeredPercentage={answeredPercentage}
                answeredCorrectly={answeredCorrectly}
                totalNoOfTrialsAnswered={totalNoOfTrialsAnswered}
              />
            </div>
            <div className="contact-us">
              <IonItem color="none" className="ion-text-center ion-no-padding ion-no-margin">
                <div>
                  <div className="contact-us-text">{t('WOW!')}</div>
                  <IonText className="contact-us-text-2">{t('You have amazing senses!')}</IonText>
                  <IonText className="contact-us-text-2">
                    {t('Contact us in order to be friends!')}
                  </IonText>
                </div>
              </IonItem>
            </div>
            <div className="contact-us">
              {isAuthenticated &&
                averageScore.average >= MINIMUM_CONTACT_US_PERCENTAGE &&
                averageScore.total >= MINIMUM_NUMBER_OF_SESSIONS && (
                  <>
                    <IonItem color="none" className="ion-text-center ion-no-padding ion-no-margin">
                      <IonText className="contact-us-text">
                        {t('Contact us in order to be friends!')}
                      </IonText>
                    </IonItem>
                    <IonItem color="none" className="ion-text-center ion-no-padding ion-no-margin">
                      <IonButton className="results-button" href={contactUsEmail}>
                        {t('Contact us')}
                      </IonButton>
                    </IonItem>
                  </>
                )}
            </div>
          </div>
          {isAuthenticated && (
            <div className="general-stats-area">
              <h5 className="general-stats">{t('General stats')}</h5>
              <GeneralStats />
            </div>
          )}
        </IonList>
        <div className="spacer" />
        <Answers trials={trials} currentRoute="/results" />
      </IonContent>
    </IonPage>
  );
};

export default SessionResults;
