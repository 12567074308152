import {
  IonContent,
  IonPage,
  IonLabel,
  IonButton,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonList,
  IonText,
  CheckboxChangeEventDetail,
  useIonModal,
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LookerSettings.scoped.scss';
import { useAppDispatch } from '../../store/store';
import {
  subjectLookerRelationshipOptions,
  genderOptions,
  ItemType,
  yearOptions,
  allowShareLocation,
} from '../../appConfigurations';
import {
  allowShareLocationSelector,
  isAuthenticatedSelector,
  locationPermissionSelector,
  lookerSelector,
  participantSelector,
  playSoundsSelector,
  resetAll,
  sessionIdSelector,
  setParticipant,
  setSubjectLookerRelationShip,
  subjectLookerRelationshipSelector,
} from '../../store/slices';
import { useSelector } from 'react-redux';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import WarningModal from '../../components/WarningModal/WarningModal';
import BackButton from '../../components/BackButton/BackButton';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import {
  useLookerCancelMutation,
  useSetLookerSubjectRelationshipMutation,
  useUpdateLookerSettingsMutation,
} from '../../store/api';
import {
  SessionIdRequest,
  SetLookerSubjectRelationship,
  UpdateLookerSettingsRequest,
} from '../../models';
import { replace } from 'redux-first-history';

const LookerSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const participant = useSelector(participantSelector);
  const playSoundsStore = useSelector(playSoundsSelector);
  const subjectLookerRelationshipStore = useSelector(subjectLookerRelationshipSelector);
  const [lookerCancel] = useLookerCancelMutation();
  const [updateLookerSettings] = useUpdateLookerSettingsMutation();
  const [setLookerSubjectRelationship] = useSetLookerSubjectRelationshipMutation();
  const sessionId = useSelector(sessionIdSelector);
  const looker = useSelector(lookerSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const allowShareLocationStore = useSelector(allowShareLocationSelector);

  const [present, dismiss] = useIonModal(WarningModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const openModal = (back: Function) => {
    present({
      onWillDismiss: async (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'accept') {
          const response = await lookerCancel({
            sessionId: sessionId,
          } as SessionIdRequest).unwrap();
          if (response) {
            dispatch(resetAll());
            back();
          }
        }
      },
    });
  };

  const { register, handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: {
      play_sounds: playSoundsStore,
      gender: participant?.gender,
      year_of_birth: participant?.year_of_birth,
      shareLocation: isAuthenticated
        ? allowShareLocationStore
        : !!(participant?.latitude && participant?.longitude),
      latitude: participant?.latitude,
      longitude: participant?.longitude,
      subjectLookerRelationship: subjectLookerRelationshipStore,
      group: participant?.group,
    },
  });
  const shareLocation = getValues('shareLocation');
  const locationPermission = useSelector(locationPermissionSelector);

  const onSubmit = async (data: FieldValues) => {
    const {
      gender,
      play_sounds,
      year_of_birth,
      subjectLookerRelationship,
      group,
      latitude,
      longitude,
    } = data || {};

    dispatch(
      setParticipant({
        gender,
        play_sounds,
        year_of_birth,
        latitude,
        longitude,
        group,
        /*      name: looker?.name*/
      })
    );

    dispatch(setSubjectLookerRelationShip(subjectLookerRelationship));

    const response = await updateLookerSettings({
      lookerId: looker?.id,
      settings: {
        gender,
        play_sounds,
        year_of_birth,
        latitude,
        longitude,
        group,
      },
    } as UpdateLookerSettingsRequest).unwrap();

    if (subjectLookerRelationship) {
      await setLookerSubjectRelationship({
        sessionId: sessionId,
        relationship: subjectLookerRelationship,
      } as SetLookerSubjectRelationship);
    }

    if (response) {
      dispatch(replace('/connection-successful-looker'));
    }
  };

  useEffect(() => {
    allowShareLocation(shareLocation, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareLocation]);

  return (
    <IonPage>
      <IonContent
        fullscreen={true}
        forceOverscroll={false}
        className="looker-settings-content looker-settings-bg"
      >
        <IonList className="looker-settings-wrapper">
          <BackButton conditionalNavigation={(back: Function) => openModal(back)} />
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <h4 className="page-title">{t('Looker')}</h4>
          </IonItem>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <IonText className="subtitle">{t('A few tweaks before you start')}</IonText>
          </IonItem>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label"
            >
              <IonLabel>{t('Your relationship with Subject')}</IonLabel>
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin custom-item-select"
            >
              <IonSelect
                interface="popover"
                className="select-settings-option"
                placeholder={t('Please select your relationship')}
                {...register('subjectLookerRelationship')}
              >
                {subjectLookerRelationshipOptions?.length &&
                  subjectLookerRelationshipOptions.map((item: ItemType) => (
                    <IonSelectOption key={item.id} value={item.option}>
                      {t(item.text)}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label item-with-checkbox"
            >
              <IonLabel className="settings-label-text">{t('Sounds')}</IonLabel>
              <Controller
                control={control}
                name="play_sounds"
                render={({ field: { onChange, value } }) => (
                  <IonCheckbox
                    className="ion-no-margin"
                    checked={value}
                    onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                      onChange(e?.detail.checked);
                    }}
                  />
                )}
                defaultValue={playSoundsStore}
                rules={{
                  required: false,
                }}
              />
            </IonItem>
            <hr />
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label"
            >
              <IonLabel>{t('Your birth year')}</IonLabel>
            </IonItem>
            <IonItem lines="none" className="ion-no-padding ion-no-margin custom-item-select">
              <IonSelect
                interface="action-sheet"
                className="select-settings-option looker"
                placeholder={t('Please select a year')}
                disabled={!!(isAuthenticated && participant?.year_of_birth)}
                {...register('year_of_birth')}
              >
                {yearOptions?.length &&
                  yearOptions.map((item: number, index: number) => (
                    <IonSelectOption key={index} value={item} className="select-settings-option">
                      {item}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label"
            >
              <IonLabel>{t('Gender')}</IonLabel>
            </IonItem>
            <IonItem lines="none" className="ion-no-padding ion-no-margin custom-item-select">
              <IonSelect
                interface="popover"
                className="select-settings-option"
                placeholder={t('Please select your gender')}
                disabled={!!(isAuthenticated && participant?.gender)}
                {...register('gender')}
              >
                {genderOptions?.length &&
                  genderOptions.map((item) => (
                    <IonSelectOption
                      key={item.id}
                      value={item.option}
                      className="select-settings-option"
                    >
                      {item.text}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin settings-label item-with-checkbox allow-location"
            >
              <IonLabel className="settings-label-text">{t('Allow location sharing')}</IonLabel>
              <Controller
                control={control}
                name="shareLocation"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <IonCheckbox
                    className="ion-no-margin"
                    checked={locationPermission === 'denied' ? false : value}
                    disabled={locationPermission === 'denied'}
                    onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                      onChange(e?.detail.checked);
                      allowShareLocation(e?.detail.checked, setValue);
                    }}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-margin ion-no-padding fixed-button"
            >
              <IonButton className="tertiary-type-button center-button" type="submit">
                {t('Next')}
              </IonButton>
            </IonItem>
          </form>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default LookerSettings;
