import { IonItem, IonList, IonModal, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './Answers.scoped.scss';
import { TrialExtraKeys } from '../../models';
import { useEffect, useState } from 'react';
import { RouterState } from 'redux-first-history';
import { useSelector } from 'react-redux';
import { currentRouteSelector } from '../../store/store';
import { DONT_WATCH_SUBJECT } from '../../appConfigurations';
import correctAnswer from '../../images/correct_answer.svg';
import skipAnswer from '../../images/skip_answer.svg';
import wrongAnswer from '../../images/wrong_answer.svg';
import TrialResults from '../TrialResults/TrialResults';

type AnswersProps = {
  trials: TrialExtraKeys[];
  currentRoute: string;
};

const Answers: React.FC<AnswersProps> = ({ trials, currentRoute }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [breakpoint, setBreakPoint] = useState(0.1);
  const { location }: RouterState = useSelector(currentRouteSelector);

  trials =
    trials?.map(
      (trial) =>
        ({
          ...trial,
          lookerWatchSubjectInstruction:
            trial.looker_watch_subject === DONT_WATCH_SUBJECT
              ? t('Don’t look at the subject')
              : t('Look at the subject'),
          icon:
            trial.subject_answer === trial.looker_watch_subject
              ? correctAnswer
              : trial.subject_answer! > 1 || trial.subject_answer === null
              ? skipAnswer
              : wrongAnswer,
        }) as TrialExtraKeys
    ) || [];

  useEffect(() => {
    if (!location?.pathname.includes(currentRoute)) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const onBreakPoint = ({ detail: { breakpoint } }: { detail: { breakpoint: number } }) => {
    setBreakPoint(breakpoint);
  };

  const initialBreakpoint = 110 / window.innerHeight;

  return (
    <IonModal
      isOpen={open}
      initialBreakpoint={initialBreakpoint}
      breakpoints={[initialBreakpoint, 0.25, 0.5, 0.75, 0.9, 1]}
      backdropBreakpoint={1}
      backdropDismiss={false}
      handle={false}
      swipeToClose={false}
      handleBehavior="cycle"
      onIonBreakpointDidChange={onBreakPoint}
      onTouchStart={(e) => setBreakPoint(1)}
    >
      <IonItem color="none" className="ion-no-margin ion-no-padding slider-line" />
      <IonList lines="none" className="answers-wrapper">
        <div className="ion-no-margin ion-no-padding answers-header">
          <div className="custom-slider-line" />
          <h5 className="detail-title">{t('Detailed answers')}</h5>
        </div>
        <IonItem className="ion-text-center ion-no-margin ion-no-padding subtitle-wrapper">
          <IonText className="subtitle">{t('Swipe up for more details')}</IonText>
        </IonItem>
        <IonItem className="ion-text-center ion-no-margin ion-no-padding subtitle-wrapper">
          <IonText className="how-you-did">{t('See how you did')}</IonText>
        </IonItem>
        <TrialResults
          trials={trials}
          height={`calc(${100 * breakpoint}% - 146px)`}
          sessionPage={false}
        />
      </IonList>
    </IonModal>
  );
};

export default Answers;
