import { IonButton, IonItem, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import WarningModalWrapper from '../WarningModalWrapper/WarningModalWrapper';
import './WarningModal.scoped.scss';
import { useSelector } from 'react-redux';
import { participantSelector } from '../../store/slices';
import { TYPE_SUBJECT } from '../../appConfigurations';

const WarningModal = ({
  onDismiss,
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const { t } = useTranslation();
  const { type: participantType } = useSelector(participantSelector);

  return (
    <WarningModalWrapper
      onDismiss={() => onDismiss(null, 'cancel')}
      closeButton={false}
      warningModal={true}
    >
      <IonItem
        lines="none"
        color="none"
        className={`ion-no-padding ion-no-margin ${
          participantType === TYPE_SUBJECT ? 'subject' : 'looker'
        }`}
      >
        <h3>{t('Are you sure?')}</h3>
      </IonItem>
      <div className="text-container">
        <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
          <IonText className="session-end">{t('The session will end')}</IonText>
        </IonItem>
      </div>
      <IonItem
        lines="none"
        color="none"
        className="ion-no-padding ion-no-margin modal-buttons warning-modal"
      >
        <div className="buttons">
          <IonButton
            className="primary-type-button-small cancel-btn"
            onClick={() => onDismiss(null, 'cancel')}
          >
            {t('Cancel')}
          </IonButton>
          <IonButton
            className={
              participantType === TYPE_SUBJECT
                ? 'secondary-type-button-small'
                : 'tertiary-type-button-small'
            }
            onClick={() => onDismiss(null, 'accept')}
          >
            {t('Yes')}
          </IonButton>
        </div>
      </IonItem>
    </WarningModalWrapper>
  );
};

export default WarningModal;
