import { User } from './Auth';

export const SESSION_NAME = 'session';
export type ParticipantType = 'looker' | 'subject';

export interface SessionState {
  numberOfTrials: number;
  timeBoxedTrial: boolean;
  trialDuration: number;
  playSounds: boolean;
  skipTrial: boolean;
  language: string;
  completeSessions?: Session[] | [];
  allSessions?: Session[] | [];
  sessionId?: number | null;
  subject: Partial<Participant> & { id: number | null; user_id: number | null };
  looker: Partial<Participant> & { id: number | null; user_id: number | null };
  trials: Trial[];
  blindfold: boolean | number;
  subject_looker_relationship: number | null;
  code: string;
  status: number;
  canAnswer: boolean;
  answered_correctly: number;
  total_no_of_trials_answered: number;
  answered_percentage: number;
  contactEmail: string | null;
  averageScore: {
    average: number;
    total: number;
  };
  generalStats: {
    totalNumberOfCorrectAnswers: number;
    totalNumberOfTrials: number;
    cumulativeAverage: number;
  };
  tooltipData: {
    leftPosition: number | null;
    selectedSession: number | null;
    percentage: string | null;
  };
}

export interface Session {
  id: number;
  subject_blindfold: boolean;
  can_skip: boolean;
  subject_looker_relationship: number | null;
  time_to_answer: number;
  status: number;
  answered_correctly: number;
  total_no_of_trials_answered: number;
  answered_percentage: number;
  statistics: Statistics | null;
  trials: Trial[];
  subject: Partial<Participant> & { id: number | null; user_id: number | null };
  looker: Partial<Participant> & { id: number | null; user_id: number | null };
  code: string;
  contact_email: string | null;
  updated_at?: string;
}

export interface Statistics {
  total_actions: number | null;
  state: number | null;
  no_stare: number | null;
  no_stare_correct: number | null;
}

export interface Trial {
  id: number;
  index: number;
  looker_watch_subject: number;
  subject_answer: number | null;
  subject_can_answer: boolean | number;
  session_id: number;
  updated_at: string;
}

export type TrialResult = {
  type: string;
  result: string;
};

export interface Participant {
  id?: number | null;
  user?: User | null;
  type: number | null;
  ready: boolean;
  play_sounds: boolean;
  year_of_birth: number | null;
  gender: number | null;
  latitude?: string | null;
  longitude?: string | null;
  feedback: string | null;
  group?: string | null;
  name: string | undefined | null;
}

export interface ParticipantIdSessionId {
  participantId: number;
  sessionId: number;
}

export interface SessionId {
  sessionId: number;
}

export interface AddLookerToSession {
  sessionId: number | string;
  userSettings: Participant;
  joinMethod?: string;
}

export interface SetUserAnswer {
  sessionId: number;
  currentIndex: number;
  subject_answer: number;
}

export interface CreateSession {
  sessionSettings: Partial<Session> & {
    numberOfTrials: number | string;
  };
  userSettings: Participant;
}

export interface UpdateLookerSettings {
  lookerId: number;
  settings: Participant;
}

export interface SetLookerSubjectRelationship {
  sessionId: number;
  relationship: number | null;
}

export interface HasFeedback {
  participant: number;
  feedback: string;
  sessionId: number;
}

export interface PaginationLink {
  url: null;
  label: string;
  active: boolean;
}

export interface Pagination {
  data: any[];
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: PaginationLink[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export interface SessionListResponse extends Pagination {
  data: Session[];
}

export type ExtraKeys = {
  subjectLookerRelationship: string;
  trials: TrialExtraKeys[];
  statusIcon: string;
  participantRole: string;
  statusText: string;
};

export type GeneralStatsType = {
  totalNumberOfCorrectAnswers: number;
  totalNumberOfTrials: number;
  cumulativeAverage: number;
};

export type SessionResponse = Session;
export type TrialResponse = Trial;
export type TrialsResponse = Trial[];
export type SessionIdRequest = SessionId;
export type ParticipantIdSessionIdRequest = ParticipantIdSessionId;
export type AddLookerToSessionRequest = AddLookerToSession;
export type AddLookerToSessionResponse = Session;
export type SetUserAnswerRequest = SetUserAnswer;
export type CreateSessionRequest = CreateSession;
export type UpdateLookerSettingsRequest = UpdateLookerSettings;
export type SetLookerSubjectRelationshipRequest = SetLookerSubjectRelationship;
export type HasFeedbackRequest = HasFeedback;
export type TrialExtraKeys = Partial<Trial> & {
  lookerWatchSubjectInstruction: string;
  icon: string;
};
export type SessionExtraKeys = Partial<Session> & ExtraKeys;
export type GeneralStatsResponse = GeneralStatsType;
