import { Suspense } from 'react';
import { IonApp } from '@ionic/react';
import { setupIonicReact } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/index.scss';
import ApiService from './services/api.service';
import { Menu } from './pages/Menu/Menu';
import { AppRouter } from './App.router';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { history } from './store/store';
import SoundLoader from './components/SoundLoader/SoundLoader';
import KeepScreenAwake from './components/KeepScreenAwake/KeepScreenAwake';
import ErrorHandler from './components/ErrorHandler/ErrorHandler';
import GetCurrentSession from './components/GetCurrentSession/GetCurrentSession';
import CheckLocationPermissions from './components/CheckLocationPermissions/CheckLocationPermissions';
require('dotenv').config();

ApiService.init(process.env.REACT_APP_BASE_URL);

setupIonicReact({ mode: 'ios', scrollAssist: false });

const App: React.FC = () => (
  <IonApp>
    <Suspense fallback={''}>
      <Router history={history}>
        {process.env.NODE_ENV === 'development' && <Menu />}
        <AppRouter />
      </Router>
    </Suspense>
    <SoundLoader />
    <KeepScreenAwake />
    <ErrorHandler />
    <GetCurrentSession />
    <CheckLocationPermissions />
  </IonApp>
);

export default App;
