import { IonButton, IonImg, IonInput, IonItem, IonLabel, IonList, IonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../images/close.svg';
import './UpdatePasswordModal.scoped.scss';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdatePasswordMutation } from '../../store/api';
import { UserPasswordUpdateRequest } from '../../models';

const UpdatePasswordModal = ({
  onDismiss,
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const { t } = useTranslation();
  const [updateUserPassword] = useUpdatePasswordMutation();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const { handleSubmit, register } = useForm();
  const onSubmit = async (data: any) => {
    try {
      let userData = Object.keys(data)
        .filter((element) => data[element] && data[element] !== '')
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: data[key],
          });
        }, {});

      const updatePasswordResult = await updateUserPassword(
        userData as UserPasswordUpdateRequest
      ).unwrap();

      if (updatePasswordResult) {
        onDismiss(null, 'confirm');
      }
    } catch (e: any) {
      const errorsMessage = Object.values(e?.data?.errors).join('\n');
      setErrorMessage(true);
      setToastMessage(errorsMessage);
      setShowToast(true);
    }
  };
  return (
    <div className="modal-wrapper">
      <IonList className="modal-items">
        <IonItem lines="none" color="none" className="ion-padding-bottom ion-no-margin">
          <IonLabel className="modal-title">{t('Update Password')}</IonLabel>
          <IonButton
            slot="end"
            fill="clear"
            className="close-modal-button"
            onClick={() => onDismiss(null, 'cancel')}
          >
            <IonImg src={closeIcon} alt="close modal icon" />
          </IonButton>
        </IonItem>
        <form onSubmit={handleSubmit(onSubmit)} className="ion-padding edit-profile-form">
          <IonLabel className="password-label">{t('Current Password')}</IonLabel>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <IonInput
              placeholder={t('Current Password')}
              type="password"
              className="custom-input"
              {...register('current_password')}
            />
          </IonItem>
          <IonLabel className="password-label">{t('New Password')}</IonLabel>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <IonInput
              type="password"
              placeholder={t('New password')}
              className="custom-input"
              {...register('password')}
            />
          </IonItem>
          <IonLabel className="password-label">{t('Confirm password')}</IonLabel>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <IonInput
              type="password"
              className="custom-input"
              placeholder={t('Confirm new password')}
              {...register('password_confirmation')}
            />
          </IonItem>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin modal-buttons">
            <div className="buttons-wrap">
              <IonButton
                className="primary-type-button-small"
                onClick={() => onDismiss(null, 'cancel')}
              >
                {t('Cancel')}
              </IonButton>
              <IonButton className="primary-type-button-small" type="submit">
                {t('Save')}
              </IonButton>
            </div>
          </IonItem>
        </form>
      </IonList>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={errorMessage ? 'danger' : 'success'}
      />
    </div>
  );
};

export default UpdatePasswordModal;
