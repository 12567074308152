import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonImg,
  IonList,
} from '@ionic/react';
import './WelcomePage.scoped.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import arrow from '../../images/arrow.svg';
import settingsIcon from '../../images/settings-new.svg';
import landingPageLogo from '../../images/staring-logo.svg';
import { currentRouteSelector, useAppDispatch } from '../../store/store';
import { push, RouterState } from 'redux-first-history';
import SplashScreen from '../SplashScreen/SplashScreen';
import { useSelector } from 'react-redux';
import { useEndRunningSessions } from '../../hooks/useEndRunningSessionsHook';

const Welcome = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { previousLocations }: RouterState = useSelector(currentRouteSelector);
  useEndRunningSessions();

  const goToSettingsPage = () => {
    dispatch(push('/settings'));
  };

  const startSessionAsGuest = () => {
    dispatch(push('/new-session-as-guest'));
  };

  const onRegister = () => {
    dispatch(push('/register'));
  };

  const onLogin = () => {
    dispatch(push('/login'));
  };

  const goToHowAppWorks = () => {
    dispatch(push('/how-our-app-works'));
  };

  const waiting = () => {
    setIsLoading((isLoading) => !isLoading);
  };

  if (
    isLoading &&
    previousLocations &&
    previousLocations.length <= 2 &&
    process.env.NODE_ENV !== 'development'
  ) {
    setTimeout(waiting, 4000);
    return <SplashScreen />;
  }

  return (
    <IonPage>
      <IonContent className="start-page" scroll-y={false}>
        <IonGrid className="grid-container">
          <IonRow className="app-logo">
            <IonCol className="app-name ion-no-margin ion-no-padding">
              <IonButton onClick={goToSettingsPage} className="small-settings-button">
                <IonImg className="settings-icon" src={settingsIcon} alt="settings icon" />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonList className="landing-page-introduction">
            <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
              <div className="logo-container">
                <IonImg src={landingPageLogo} alt="landing page logo" />
              </div>
            </IonItem>
            <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
              <h4 className="page-title">{t('Are you ready to test your senses?')}</h4>
            </IonItem>
            <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
              <span className="how-app-works-subtitle">
                {t(
                  "This is a fun way to test and improve your capacity to sense the looks of others when you can't see them."
                )}
              </span>
            </IonItem>
            <IonItem
              lines="none"
              color="none"
              className="ion-no-padding ion-no-margin how-it-works-button-item"
            >
              <IonButton class="how-it-works-button" onClick={goToHowAppWorks}>
                {t('HOW OUR APP WORKS')}
                <IonImg src={arrow} alt="arrow icon" className="how-app-works-arrow" />
              </IonButton>
            </IonItem>
            <div className="bottom-buttons">
              <IonButton class="primary-type-button" onClick={onRegister}>
                {t('GET STARTED')}
              </IonButton>
              <IonButton class="primary-type-button second-color" onClick={startSessionAsGuest}>
                {t('PLAY AS GUEST')}
              </IonButton>
              <IonItem lines="none" color="none" className="ion-no-padding">
                <span className="login-container" onClick={onLogin}>
                  <span className="first-text">{t('Already have an account? ')}</span>
                  {t('Log in')}
                </span>
              </IonItem>
            </div>
          </IonList>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
