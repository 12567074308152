import { IonContent, IonGrid, IonItem, IonPage, IonText, IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/BackButton/BackButton';
import './ScanTheQr.scoped.scss';
import { Device } from '@capacitor/device';
import { useSelector } from 'react-redux';
import {
  lookerSelector,
  playSoundsSelector,
  subjectLookerRelationshipSelector,
} from '../../store/slices';
import { useAddLookerToSessionMutation } from '../../store/api';
import { currentRouteSelector, useAppDispatch } from '../../store/store';
import { replace, RouterState } from 'redux-first-history';
import { Html5Qrcode } from 'html5-qrcode';

type ScanTheQrProps = {};

const ScanTheQr: React.FC<ScanTheQrProps> = () => {
  const { t } = useTranslation();
  const [scanner, setScanner] = useState<Html5Qrcode | null | undefined>(null);
  const playSounds = useSelector(playSoundsSelector);
  const looker = useSelector(lookerSelector);
  const subjectLookerRelationship = useSelector(subjectLookerRelationshipSelector);
  const [addLookerToSession] = useAddLookerToSessionMutation();
  const [toastMessage, setToastMessage] = useState('');
  const dispatch = useAppDispatch();
  const { location }: RouterState = useSelector(currentRouteSelector);

  useEffect(() => {
    // Initialize the QR code scanner when the component mounts
    Html5Qrcode.getCameras()
      .then((devices) => {
        const qrCodeScanner = new Html5Qrcode('qr-reader');
        setScanner(qrCodeScanner);

        if (devices && devices.length) {
          const backCameras = devices.filter((deviceCamera) => deviceCamera.label.includes('back'));
          let cameraIdOrConfig: string | object;

          //get back camera id or set default environment
          if (backCameras.length) {
            cameraIdOrConfig = backCameras[backCameras.length - 1].id;
          } else {
            cameraIdOrConfig = { facingMode: 'environment' };
          }

          qrCodeScanner?.start(
            cameraIdOrConfig,
            { fps: 10, qrbox: 150 },
            async (decodedText: string) => {
              // Handle the QR code scan result here
              await handleScan(decodedText, qrCodeScanner);
            },
            (/*err*/) => {
              // These errors are usually ongoing for each image not matching a qr code and can be ignored
              // console.log('Scanner error');
            }
          );
        }
      })
      .catch((err) => {
        console.log('err', err);
        setToastMessage(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScan = async (data: string, paramScanner: Html5Qrcode | undefined) => {
    const activeScanner = paramScanner || scanner;
    if (data) {
      const info = await Device.getInfo();

      try {
        const userSettings = {
          type: 1,
          ready: false,
          play_sounds: playSounds,
          year_of_birth: looker?.year_of_birth || null,
          gender: looker?.gender || null,
          latitude: looker?.latitude || '',
          longitude: looker?.longitude || '',
          feedback: looker?.feedback || '',
          name: info?.model,
          group: looker?.group || '',
          subject_looker_relationship: subjectLookerRelationship,
        };

        const response = await addLookerToSession({ sessionId: data, userSettings }).unwrap();

        if (response) {
          dispatch(replace('/looker-settings'));
          await activeScanner?.stop();
          await activeScanner?.clear();
        }
        //await this.handleReady();
      } catch (error: any) {
        if (error.status === 403) {
          setToastMessage('Someone is already connected to this session');
        }

        if (error.status === 404) {
          setToastMessage('This code is not associated with any session');
        }

        if (error.status > 404) {
          setToastMessage('Error from server, please try again');
        }
      }
    }
  };

  // Destroy on leave
  useEffect(() => {
    const asyncFunc = async () => {
      if (location?.pathname !== '/scan-qr-looker') {
        if (scanner) {
          await scanner?.stop();
          await scanner?.clear();
          setScanner(undefined);
        }
      }
    };
    asyncFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return (
    <IonPage>
      <IonContent>
        <IonGrid className="scan-the-qr">
          <IonItem lines="none" className="ion-no-margin ion-no-padding back-container">
            <BackButton defaultPath="/lets-connect-looker" />
          </IonItem>
          <h4 className="page-title">{t('Looker')}</h4>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <h3 className="scan-qr-text">{t('Scan the QR')}</h3>
          </IonItem>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <IonText className="subtitle">
              {t('Scan the Subject’s QR code to begin the test')}
            </IonText>
          </IonItem>
          <div className="scanning-line" />
          <div className="reader-wrapper">
            <div id="qr-reader" className="scanner" />
            <div className="corner corner-1" />
            <div className="corner corner-2" />
            <div className="corner corner-3" />
            <div className="corner corner-4" />
          </div>
          <IonToast
            isOpen={!!toastMessage}
            onDidDismiss={() => setToastMessage('')}
            message={toastMessage}
            duration={3000}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ScanTheQr;
