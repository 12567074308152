import { createListenerMiddleware } from '@reduxjs/toolkit';
import { AuthApi } from '../api';
import { history } from '../store';
import { Toast } from '../../components/ErrorHandler/ErrorHandler';

export const apiExceptionsMiddleware = createListenerMiddleware();

apiExceptionsMiddleware.startListening({
  predicate: (action) => {
    return (
      action.type.includes('executeQuery/rejected') &&
      action['payload']?.status &&
      !AuthApi.endpoints.logOutUser.matchRejected(action)
    );
  },
  effect: async ({ payload: { status, data } }, { dispatch }) => {
    if (status === 401) {
      await dispatch(AuthApi.endpoints.logOutUser.initiate());
      history.push('/login');
    }

    if (status >= 400) {
      Toast.showToast(data.message);
    }
  },
});
