import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  useIonModal,
  IonText,
  IonImg,
} from '@ionic/react';
import {
  currentIndexSelector,
  numberOfTrialsSelector,
  sessionIdSelector,
  skipTrialSelector,
  canAnswerSelector,
  participantSelector,
} from '../../store/slices';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './SessionWrapper.scoped.scss';
import { SessionIdRequest, SetUserAnswerRequest } from '../../models';
import { SUBJECT_ANSWER_SKIP, TYPE_SUBJECT } from '../../appConfigurations';
import {
  SessionApi,
  useLookerCancelMutation,
  useSetUserResponseMutation,
  useSubjectCancelMutation,
} from '../../store/api';
import { useAppDispatch } from '../../store/store';
import WarningModal from '../WarningModal/WarningModal';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import React, { useEffect, useState } from 'react';
import { replace } from 'redux-first-history';
import arrow from '../../images/arrow-white.svg';

type SessionWrapperProps = {
  children: React.ReactNode;
  lookerName?: string | null;
  subjectName?: string | null;
};

const SessionWrapper = ({ children, lookerName, subjectName }: SessionWrapperProps) => {
  const [requestLoading, setRequestLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const numberOfTrials = useSelector(numberOfTrialsSelector);
  const isSkipTrial = useSelector(skipTrialSelector);
  const currentIndex = useSelector(currentIndexSelector) || 0;
  const sessionId = useSelector(sessionIdSelector);
  const canAnswer = useSelector(canAnswerSelector);
  const { type: participantType } = useSelector(participantSelector);
  const [setUserResponse] = useSetUserResponseMutation();
  const [subjectCancel] = useSubjectCancelMutation();
  const [lookerCancel] = useLookerCancelMutation();
  const finished = currentIndex === -1;
  const currentIndexRender = finished ? numberOfTrials : currentIndex + 1;
  const hideSkipButton = !!(participantType === TYPE_SUBJECT && isSkipTrial && !finished);

  const [present, dismiss] = useIonModal(WarningModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const endSession = () => {
    present({
      onWillDismiss: async (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'accept' && !requestLoading) {
          setRequestLoading(true);
          try {
            if (participantType === TYPE_SUBJECT) {
              await subjectCancel({ sessionId: sessionId } as SessionIdRequest).unwrap();
            } else {
              await lookerCancel({ sessionId: sessionId } as SessionIdRequest).unwrap();
            }
          } catch (error) {
            console.log(error);
            setRequestLoading(false);
          }
        }
      },
    });
  };

  const skipTrial = async () => {
    if (!requestLoading && canAnswer) {
      setRequestLoading(true);
      try {
        await setUserResponse({
          sessionId,
          currentIndex,
          subject_answer: SUBJECT_ANSWER_SKIP,
        } as SetUserAnswerRequest).unwrap();

        await dispatch(
          SessionApi.endpoints.getTrials.initiate({ sessionId } as SessionIdRequest, {
            forceRefetch: true,
          })
        );
      } catch (error) {
        console.log(error);
      }
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    if (finished) {
      dispatch(replace('/end-of-session'));
    }
  }, [finished, dispatch]);

  return (
    <IonPage>
      <IonContent
        className={participantType === TYPE_SUBJECT ? 'subject-settings-bg' : 'looker-settings-bg'}
        forceOverscroll={false}
      >
        <IonGrid className="session-wrapper page-wrapper">
          <IonRow className="row-session-buttons">
            <IonCol className="session-buttons">
              <div className="first-btn">
                {!finished ? (
                  <IonButton fill="clear" className="session-button" onClick={endSession}>
                    {t('End session')}
                  </IonButton>
                ) : (
                  <div className="current-index ion-text-left done-text">{t('Done')}</div>
                )}
              </div>
              <div className={`current-index ${!finished ? 'ion-text-center' : 'text-final'}`}>
                {t(`${currentIndexRender} of ${numberOfTrials}`)}
              </div>
              <span className="skip-btn-container">
                {hideSkipButton && (
                  <IonButton
                    fill="clear"
                    className="session-button"
                    onClick={skipTrial}
                    disabled={!canAnswer || requestLoading}
                  >
                    {t('Skip')}
                    <IonImg className="skip-icon" src={arrow} alt="skip icon" />
                  </IonButton>
                )}
              </span>
            </IonCol>
          </IonRow>
          <IonRow className="progress-bar">
            <progress value={currentIndexRender} max={numberOfTrials} />
          </IonRow>
          {!finished ? (
            <div className="layer-one">
              <div className="session-header">
                <IonRow className="who-is-connected connected-with">
                  <IonCol>
                    <IonCol size="auto" className="role">
                      <IonText className="connected-with-text">{t('CONNECTED WITH')}</IonText>
                    </IonCol>
                  </IonCol>
                </IonRow>
                <IonRow className="who-is-connected">
                  <IonCol>
                    <IonCol className="role participant-name">
                      <IonText className="connected-with">{lookerName || subjectName}</IonText>
                    </IonCol>
                  </IonCol>
                </IonRow>
              </div>
              <div className="session-content">{children}</div>
            </div>
          ) : (
            <>{children}</>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SessionWrapper;
