import SessionWrapper from '../../components/SessionWrapper/SessionWrapper';
import { IonImg, IonItem, IonList } from '@ionic/react';
import watchIcon from '../../images/watch_icon.svg';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentTrialSelector, lookerSelector, subjectSelector } from '../../store/slices';
import { useAppDispatch } from '../../store/store';
import { replace } from 'redux-first-history';
import './WaitForNextTrials.scoped.scss';
import { soundFiles } from '../../components/SoundLoader/SoundLoader';

const WaitForNextTrial: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const looker = useSelector(lookerSelector);
  const subject = useSelector(subjectSelector);
  const currentTrial = useSelector(currentTrialSelector);

  // On index change unblock current trial if subject can answer
  useEffect(() => {
    if (currentTrial && currentTrial.subject_can_answer) {
      // Waiting period has ended, looker confirmed start next trial
      dispatch(replace('/subject-flow'));
    }
  }, [dispatch, currentTrial]);

  useEffect(() => {
    if (
      // Opted in to play sounds
      subject?.play_sounds &&
      // Sound loaded
      soundFiles.endTrial.wasLoaded
    ) {
      setTimeout(() => {
        soundFiles.endTrial.play();
      }, 500);
    }
  }, [subject?.play_sounds]);

  return (
    <SessionWrapper
      lookerName={
        looker?.user?.name
          ? t("{{name}}'s {{device}}", { name: looker?.user?.name, device: looker?.name })
          : t("Looker's {{device}}", { device: looker?.name })
      }
    >
      <IonList lines="none" className="wait-for-next-trial-wrapper">
        <IonItem
          color="none"
          className="ion-text-center ion-no-margin ion-no-padding answer-now-wrapper wait-next-container"
        >
          <h3 className="answer-now font-medium">{t('Wait for the next trial')}</h3>
        </IonItem>
        <IonItem color="none" className="ion-no-margin ion-no-padding icon-wrapper">
          <div className="icon-container">
            <IonImg src={watchIcon} alt="watch icon" className="custom-icon" />
          </div>
        </IonItem>
        <IonItem
          color="none"
          className="ion-text-center ion-no-margin ion-no-padding ion-padding-vertical subtitle-wrapper"
        >
          <span className="subtitle">
            <div>{t('We will move on to the next trial')}</div>
            <div>{t('when the subject answers the question')}</div>
          </span>
        </IonItem>
      </IonList>
    </SessionWrapper>
  );
};

export default WaitForNextTrial;
