import { IonCol, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './GeneralStats.scoped.scss';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { setTooltip } from '../../store/slices';
import Chart from 'react-apexcharts';
import { useAppDispatch } from '../../store/store';
import ChartTooltip from '../ChartTooltip/ChartTooltip';
import { useEffect, useState } from 'react';
import { useGeneralStatsQuery, useListCompleteSessionsQuery } from '../../store/api';
import { ParticipantType } from '../../models';

const GeneralStats: React.FC<{ participant?: ParticipantType }> = ({ participant }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [fetchedParticipantTypes, setFetchedParticipantTypes] = useState<ParticipantType[]>([]);

  const { data: latestSessions } = useListCompleteSessionsQuery(
    { participant, page: 1 },
    { refetchOnMountOrArgChange: !participant || !fetchedParticipantTypes.includes(participant) }
  );
  const dataForChart = latestSessions?.data
    ?.slice(0, 15)
    .map((session) => [
      session.id,
      Math.round(session.answered_percentage * 1000) / 10,
    ]) as number[][];

  const { data: statsData } = useGeneralStatsQuery(
    { participant },
    { refetchOnMountOrArgChange: !participant || !fetchedParticipantTypes.includes(participant) }
  );

  const { totalNumberOfCorrectAnswers, totalNumberOfTrials, cumulativeAverage } = statsData || {
    totalNumberOfCorrectAnswers: 0,
    totalNumberOfTrials: 0,
    cumulativeAverage: 0,
  };

  useEffect(() => {
    if (participant) {
      setFetchedParticipantTypes(Array.from(new Set([...fetchedParticipantTypes, participant])));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  const data = {
    options: {
      chart: {
        id: 'session-chart',
        toolbar: {
          show: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#F8F3D8'],
      stroke: {
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 0,
      },
      yaxis: {
        title: {
          text: t('Correct (%)'),
          rotate: -90,
          style: {
            color: '#FFFFFF66',
            fontSize: '14px',
            fontFamily: 'Syne',
            fontWeight: 600,
          },
        },
        labels: {
          style: {
            colors: ['#FFFFFF66'],
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: 400,
          },
        },
        min: 0,
        max: 100,
        offsetX: -0.031,
        tickAmount: 4,
      },
      xaxis: {
        title: {
          text: t('Session no*'),
          style: {
            color: '#FFFFFFCC',
            fontSize: '18px',
            fontFamily: 'Syne',
            fontWeight: 600,
          },
          offsetY: -20,
          offsetX: -10,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            colors: '#FFFFFFCC',
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: 500,
          },
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        custom: ({
          series,
          seriesIndex,
          dataPointIndex,
          w,
        }: {
          series: [];
          seriesIndex: number;
          dataPointIndex: number;
          w: any;
        }) => {
          const index =
            latestSessions?.data
              ?.map((session) => session.id)
              .indexOf(dataForChart[dataPointIndex][0]) || 0;
          const pointsArray = w.globals.pointsArray;
          const leftValue = pointsArray[seriesIndex][index][0];
          const tooltipData = {
            leftPosition: leftValue,
            selectedSession: dataForChart[dataPointIndex][0],
            percentage: `${series[seriesIndex][dataPointIndex]}%`,
          };
          dispatch(setTooltip(tooltipData));
          return `<div></div>`;
        },
        shared: false,
      },
    },
    series: [
      {
        name: 'Percentage',
        data: dataForChart,
      },
    ],
  };

  return (
    <>
      <IonRow className="row-avg-score">
        <IonCol className="average-score">
          <div className="average-score-circle-wrapper">
            <CircularProgressbarWithChildren
              className="average-score-circle"
              strokeWidth={6}
              value={cumulativeAverage}
              maxValue={100}
              styles={buildStyles({
                strokeLinecap: 'round',
                pathColor: '#E295B6',
              })}
            >
              <div className="average-score-percentage-container">
                <h5 className="average-score-percentage">
                  {Number(cumulativeAverage).toFixed(0)}%
                </h5>
                <span className="cumulative-average">{t('Cumulative Average')}</span>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </IonCol>
        <IonCol className="correct-total-trials ion-no-padding">
          <div className="summary">
            <h5 className="average-score-percentage total">
              {totalNumberOfCorrectAnswers ? totalNumberOfCorrectAnswers : '0'}/
              {totalNumberOfTrials ? totalNumberOfTrials : '0'}
            </h5>
            <span className="cumulative-average second-cumulative">
              {t('Correct/Total trials')}
            </span>
          </div>
        </IonCol>
      </IonRow>
      <IonRow className="session-chart-wrapper ion-margin-top ion-justify-content-center ion-padding-top">
        <ChartTooltip />
        <div id="chart-container" className="chart-container">
          <Chart
            options={data.options}
            series={data.series}
            type="area"
            width="100%"
            height={250}
          />
        </div>
      </IonRow>
      <div className="info">
        <p>{t('*Incomplete sessions are not included')}</p>
      </div>
    </>
  );
};

export default GeneralStats;
