import { ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Action, Location } from 'history';
import './AnimatedSwitch.scss';

export type AnimatedSwitchProps = {
  location?: Location | null;
  action?: Action | null;
  previousLocation?: {
    location?: Location | null;
    action?: Action | null;
  }[];
  children?: ReactNode;
};

export const AnimatedSwitch: React.FC<AnimatedSwitchProps> = (props) => {
  const classAction = props.action?.toLowerCase() || 'push';
  const currentKey = props.location?.pathname.split('/')[1] || '/';

  return (
    <TransitionGroup className={`router-${classAction}`}>
      <CSSTransition
        key={currentKey}
        classNames="slide"
        transitionAppear={true}
        timeout={400}
        transitionEnter={false}
        transitionLeave={false}
      >
        {props.children}
      </CSSTransition>
    </TransitionGroup>
  );
};

export default AnimatedSwitch;
