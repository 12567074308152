import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import {
  AddLookerToSessionRequest,
  AddLookerToSessionResponse,
  CreateSessionRequest,
  GeneralStatsResponse,
  HasFeedbackRequest,
  Participant,
  ParticipantIdSessionIdRequest,
  ParticipantType,
  SessionIdRequest,
  SessionListResponse,
  SessionResponse,
  SetLookerSubjectRelationshipRequest,
  SetUserAnswerRequest,
  TrialResponse,
  TrialsResponse,
  UpdateLookerSettingsRequest,
} from '../../models';

export const SessionApi = createApi({
  reducerPath: 'app-api',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    listCompleteSessions: builder.query<
      SessionListResponse,
      { page?: number; participant?: ParticipantType }
    >({
      query: ({ page, participant }) => ({
        url: 'client/user-sessions',
        method: 'GET',
        params: {
          page,
          ...(participant ? { participant: participant === 'looker' ? 1 : 0 } : {}),
        },
      }),
    }),
    listAllSessions: builder.query<
      SessionListResponse,
      { page?: number; participant?: ParticipantType }
    >({
      query: ({ page, participant }) => ({
        url: 'client/user-sessions',
        method: 'GET',
        params: {
          filter: 'all',
          page,
          ...(participant ? { participant: participant === 'looker' ? 1 : 0 } : {}),
        },
      }),
    }),
    addLookerToSession: builder.mutation<AddLookerToSessionResponse, AddLookerToSessionRequest>({
      query: ({ sessionId, userSettings, joinMethod }) => ({
        url: `client/sessions/${encodeURIComponent(sessionId)}/add-looker-to-session`,
        method: 'PATCH',
        body: {
          participant: userSettings,
          joinMethod,
        },
      }),
    }),
    createSession: builder.mutation<SessionResponse, CreateSessionRequest>({
      query: ({ sessionSettings, userSettings }) => ({
        url: 'client/sessions',
        method: 'POST',
        body: {
          ...sessionSettings,
          participant: userSettings,
        },
      }),
    }),
    getCurrentSession: builder.query<SessionResponse, SessionIdRequest>({
      query: ({ sessionId }) => ({
        url: `client/sessions/${encodeURIComponent(sessionId)}`,
        method: 'GET',
      }),
    }),
    getTrials: builder.query<TrialsResponse, SessionIdRequest>({
      query: ({ sessionId }) => ({
        url: `client/trials`,
        method: 'GET',
        params: {
          session_id: encodeURIComponent(sessionId),
        },
      }),
    }),
    setUserResponse: builder.mutation<SessionResponse, SetUserAnswerRequest>({
      query: ({ sessionId, currentIndex, subject_answer }) => ({
        url: `client/trials/${encodeURIComponent(sessionId)}/${encodeURIComponent(currentIndex)}`,
        method: 'PATCH',
        body: {
          subject_answer: subject_answer,
        },
      }),
    }),
    participantReady: builder.mutation<SessionResponse, ParticipantIdSessionIdRequest>({
      query: ({ participantId, sessionId }) => ({
        url: `client/participant/${encodeURIComponent(participantId)}/ready`,
        method: 'PATCH',
        body: {
          sessionId: sessionId,
        },
      }),
    }),
    goToNextTrial: builder.mutation<TrialResponse, SessionIdRequest>({
      query: ({ sessionId }) => ({
        url: `client/sessions/${encodeURIComponent(sessionId)}/go-to-next-trial`,
        method: 'POST',
      }),
    }),
    sendEmail: builder.mutation<void, SessionIdRequest>({
      query: ({ sessionId }) => ({
        url: `client/sessions/${encodeURIComponent(sessionId)}/send`,
        method: 'POST',
      }),
    }),
    lookerCancel: builder.mutation<SessionResponse, SessionIdRequest>({
      query: ({ sessionId }) => ({
        url: `client/trials/${encodeURIComponent(sessionId)}/looker-cancel`,
        method: 'POST',
      }),
    }),
    subjectCancel: builder.mutation<SessionResponse, SessionIdRequest>({
      query: ({ sessionId }) => ({
        url: `client/trials/${encodeURIComponent(sessionId)}/subject-cancel`,
        method: 'POST',
      }),
    }),
    updateLookerSettings: builder.mutation<Participant, UpdateLookerSettingsRequest>({
      query: ({ lookerId, settings }) => ({
        url: `client/participant/${encodeURIComponent(lookerId)}/update-settings`,
        method: 'PATCH',
        body: {
          ...settings,
          lookerId,
        },
      }),
    }),
    setLookerSubjectRelationship: builder.mutation<void, SetLookerSubjectRelationshipRequest>({
      query: ({ sessionId, relationship }) => ({
        url: 'client/sessions',
        method: 'PATCH',
        body: {
          sessionId,
          relationship,
        },
      }),
    }),
    participantFeedback: builder.mutation<Participant, HasFeedbackRequest>({
      query: ({ sessionId, participant, feedback }) => ({
        url: `client/participant/${encodeURIComponent(participant)}/feedback`,
        method: 'PATCH',
        body: {
          sessionId,
          feedback,
        },
      }),
    }),
    averageScore: builder.query<{ average: number; total: number }, void>({
      query: () => ({
        url: 'client/sessions/average-score',
        method: 'GET',
      }),
    }),
    generalStats: builder.query<GeneralStatsResponse, { participant?: ParticipantType }>({
      query: ({ participant }) => ({
        url: 'client/user/general-stats',
        method: 'GET',
        params: {
          ...(participant ? { participant: participant === 'looker' ? 1 : 0 } : {}),
        },
      }),
    }),
  }),
});

export const {
  useListCompleteSessionsQuery,
  useListAllSessionsQuery,
  useAddLookerToSessionMutation,
  useGetCurrentSessionQuery,
  useGetTrialsQuery,
  useGoToNextTrialMutation,
  useSendEmailMutation,
  useParticipantReadyMutation,
  useLookerCancelMutation,
  useSubjectCancelMutation,
  useCreateSessionMutation,
  useUpdateLookerSettingsMutation,
  useSetLookerSubjectRelationshipMutation,
  useSetUserResponseMutation,
  useParticipantFeedbackMutation,
  useGeneralStatsQuery,
} = SessionApi;
