import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../store/slices';
import { Navigate } from 'react-router-dom';
import { ReactElement } from 'react';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = (props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (isAuthenticated) {
    return props.children as ReactElement;
  }

  return <Navigate to="/welcome" replace />;
};

export default ProtectedRoute;
