import { createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { SessionApi } from '../api';
import { CreateSessionRequest } from '../../models';

export const createSession = createAsyncThunk<void, CreateSessionRequest>(
  'client/createSession',
  async (createSessionRequest, { dispatch }) => {
    batch(async () => {
      try {
        await dispatch(SessionApi.endpoints.createSession.initiate(createSessionRequest)).unwrap();
      } catch (error) {
        console.log(error);
      }
    });
  }
);
