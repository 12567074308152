import { IonList, IonItem, IonImg } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import './GetReady.scoped.scss';
import countDown from '../../images/countdown.svg';

const GetReady: React.FC<{ onDone?: Function; lookerParticipant?: boolean }> = ({
  onDone,
  lookerParticipant,
}) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState('1');

  useEffect(() => {
    setTimeout(() => {
      setCounter('2');
    }, 1000);
    setTimeout(() => {
      if (onDone) {
        onDone();
      }
    }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonList lines="none" className="get-ready-items">
      <IonItem
        className={`ion-text-center ion-no-margin ion-no-padding ${lookerParticipant && 'looker'}`}
      >
        <div>{t('Get ready...')}</div>
      </IonItem>
      <IonItem className="ion-text-center ion-no-margin ion-no-padding counter">
        <div className="countdown">
          <IonImg src={countDown} alt="arrow icon" className="how-app-works-arrow" />
          <h5 className="number">{counter}</h5>
        </div>
      </IonItem>
    </IonList>
  );
};

export default GetReady;
