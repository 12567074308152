import { setupListeners } from '@reduxjs/toolkit/query';
import { Action, combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { defaultMiddleware } from './middleware';
import storage from 'redux-persist/lib/storage';
import { AuthApi, SessionApi } from './api';
import { useDispatch } from 'react-redux';
import { AuthSlice, SessionSlice } from './slices';
import { createReduxHistoryContext, RouterState } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { apiExceptionsMiddleware } from './middleware/api-exceptions';
import { AppSettingsSlice } from './slices/AppSettingsSlice';

const browserHistory = createBrowserHistory();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: browserHistory as any,
  reduxTravelling: true,
  savePreviousLocations: 5,
});

const reducers = combineReducers({
  router: routerReducer,
  [SessionSlice.name]: SessionSlice.reducer,
  [AuthSlice.name]: AuthSlice.reducer,
  [AppSettingsSlice.name]: AppSettingsSlice.reducer,

  [AuthApi.reducerPath]: AuthApi.reducer,
  [SessionApi.reducerPath]: SessionApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router', AuthApi.reducerPath, SessionApi.reducerPath],
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  middleware: (getDefaultMiddleware) => [
    ...defaultMiddleware(getDefaultMiddleware),
    routerMiddleware,
    AuthApi.middleware,
    SessionApi.middleware,
    apiExceptionsMiddleware.middleware,
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();
export const currentRouteSelector = (state: RootState) => state.router as RouterState;
export const history = createReduxHistory(store);

setupListeners(store.dispatch);
