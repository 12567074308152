import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonItem,
  IonImg,
} from '@ionic/react';
import './Login.scoped.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginUserMutation } from '../../store/api';
import { LoginRequest } from '../../models';
import { loadUser } from '../../store/actions';
import { useAppDispatch } from '../../store/store';
import { push } from 'redux-first-history';
import { FieldValues, useForm } from 'react-hook-form';
import AuthFormInputs from '../../components/AuthFormInputs/AuthFormInputs';
import landingPageLogo from '../../images/staring-logo.svg';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [login] = useLoginUserMutation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();

  const onSubmit = async (data: FieldValues) => {
    const { email, password } = data || {};

    try {
      const authResult = await login({
        email: email,
        password: password,
        appId: process.env.REACT_APP_CLIENT_ID,
      } as LoginRequest).unwrap();

      if (authResult) {
        await dispatch(loadUser());
        dispatch(push('/home'));
      }
    } catch (error: any) {
      if (error.status === 422) {
        setError('server', {
          type: 'server',
          message: error?.data?.errors?.email,
        });
      }
    }
  };

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <IonGrid className="page-wrapper auth-content-wrapper ion-no-padding">
          <IonRow>
            <IonCol>
              <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
                <div className="logo-container">
                  <IonImg src={landingPageLogo} alt="landing page logo" />
                </div>
              </IonItem>
            </IonCol>
          </IonRow>
          <div className="form-content">
            <IonRow>
              <IonCol className="ion-no-margin ion-no-padding ion-padding-bottom col-page-title">
                <h4 className="page-title">{t('Log in')}</h4>
                <div className="under-register-login-text ion-no-margin ion-no-padding ion-padding-bottom">
                  <IonText className="subtitle-auth">{t('Welcome back!')}</IonText>
                  <IonText className="subtitle-auth">
                    {t('Are you ready to try your senses?')}
                  </IonText>
                </div>
              </IonCol>
            </IonRow>
            <form onSubmit={handleSubmit(onSubmit)}>
              <AuthFormInputs
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                setValue={setValue}
              />
              <IonRow>
                <IonItem
                  color="none"
                  lines="none"
                  className="ion-no-padding ion-no-margin recovery"
                  onClick={() => dispatch(push('/recovery'))}
                >
                  <div className="recovery-text">{t('Recovery password')}</div>
                </IonItem>
              </IonRow>
              <IonRow>
                <IonButton className="primary-type-button filled-button" type="submit">
                  {t('LOG IN')}
                </IonButton>
              </IonRow>
              <IonRow>
                <IonButton
                  className="primary-type-button"
                  onClick={() => dispatch(push('/welcome'))}
                >
                  {t('BACK')}
                </IonButton>
              </IonRow>
            </form>
            <IonRow className="ion-justify-content-center not-a-member-container">
              <IonText className="not-a-member login">{t('Not a member?')}</IonText>
              <IonText className="not-a-member" onClick={() => dispatch(push('register'))}>
                {t('Register now')}
              </IonText>
            </IonRow>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
