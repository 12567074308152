import { IonIcon, IonItem, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './TrialResults.scoped.scss';
import { TrialExtraKeys } from '../../models';

type TrialResultsProps = {
  trials: TrialExtraKeys[];
  height: string;
  sessionPage: boolean;
};

const TrialResults: React.FC<TrialResultsProps> = ({ trials, height, sessionPage }) => {
  const { t } = useTranslation();

  const stopPropagation = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div
      className="trials-results"
      onTouchStartCapture={stopPropagation}
      style={{ height: height || '100%' }}
    >
      {trials.map((trial: TrialExtraKeys) => (
        <div
          key={trial.index}
          className={`trial-wrapper ${
            trial.index === trials.length - 1 ? 'trial-last-index' : ''
          } ${sessionPage && 'no-padding'}`}
        >
          <div>
            <IonItem lines="none" color="none" className="ion-no-margin ion-no-padding">
              <div className="trial-index-wrapper">
                <div className="trial-index">
                  {t(`Trial ${trial.index! + 1} of ${trials.length}`)}
                </div>
                <div>
                  <IonText className="trial-instruction">
                    {trial.lookerWatchSubjectInstruction}
                  </IonText>
                </div>
              </div>
            </IonItem>
          </div>
          <IonIcon src={trial.icon} className="answer-icon" />
        </div>
      ))}
    </div>
  );
};

export default TrialResults;
