import { useEffect } from 'react';
import { SessionApi } from '../../store/api';
import { SessionIdRequest } from '../../models';
import { REQUEST_LOOP_TIMEOUT } from '../../appConfigurations';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import { startListeningSessionIdSelector } from '../../store/slices';

const GetCurrentSession: React.FC = () => {
  const dispatch = useAppDispatch();
  const startListeningSessionId = useSelector(startListeningSessionIdSelector);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (startListeningSessionId) {
      const getCurrentSession = async () => {
        try {
          await dispatch(
            SessionApi.endpoints.getCurrentSession.initiate(
              { sessionId: startListeningSessionId } as SessionIdRequest,
              { forceRefetch: true }
            )
          );
        } catch (error) {
          console.log(error);
        }

        if (timer) {
          timer = setTimeout(getCurrentSession, REQUEST_LOOP_TIMEOUT);
        }
      };

      timer = setTimeout(getCurrentSession, REQUEST_LOOP_TIMEOUT);

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = null;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, startListeningSessionId]);

  return <></>;
};

export default GetCurrentSession;
