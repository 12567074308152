import { IonLabel, IonRow, IonCol /*IonImg, IonItem*/ } from '@ionic/react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from '@capacitor-community/app-react';
import { skipTrialSelector } from '../../store/slices';
import { useSelector } from 'react-redux';
import {
  SUBJECT_ANSWER_NO,
  SUBJECT_ANSWER_SKIP,
  SUBJECT_ANSWER_YES,
} from '../../appConfigurations';

const SpeechRecognitionComponent: React.FC<{ setSubjectResponse: Function }> = ({
  setSubjectResponse,
}) => {
  const { t } = useTranslation();
  const { state } = useAppState();
  const skipTrialOption = useSelector(skipTrialSelector);
  const commands = [
    {
      command: /.*(yes|Yes|YES)\b.*/,
      callback: () => {
        setSubjectResponse(SUBJECT_ANSWER_YES);
        resetTranscript();
        SpeechRecognition.stopListening();
      },
    },
    {
      command: /.*(no|No|NO)\b.*/,
      callback: () => {
        setSubjectResponse(SUBJECT_ANSWER_NO);
        resetTranscript();
        SpeechRecognition.stopListening();
      },
    },
    ...(skipTrialOption
      ? [
          {
            command: /.*(skip|Skip|SKIP)\b.*/,
            callback: () => {
              setSubjectResponse(SUBJECT_ANSWER_SKIP);
              resetTranscript();
              SpeechRecognition.stopListening();
            },
          },
        ]
      : []),
  ];

  const { resetTranscript, transcript, browserSupportsSpeechRecognition } = useSpeechRecognition({
    commands,
  });

  useEffect(() => {
    if (transcript) {
      if (transcript.toLowerCase().search(/yes\b/) !== -1) {
        setSubjectResponse(SUBJECT_ANSWER_YES);
        SpeechRecognition.stopListening();
      }

      if (transcript.toLowerCase().search(/no\b/) !== -1) {
        setSubjectResponse(SUBJECT_ANSWER_NO);
        SpeechRecognition.stopListening();
      }

      if (skipTrialOption && transcript.toLowerCase().search(/skip\b/) !== -1) {
        setSubjectResponse(SUBJECT_ANSWER_SKIP);
        SpeechRecognition.stopListening();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript]);

  useEffect(() => {
    SpeechRecognition.startListening({ language: 'en-US', continuous: true });
    return () => {
      SpeechRecognition.stopListening();
    };
  }, []);

  useEffect(() => {
    const startListening = async () => {
      if (state) {
        await SpeechRecognition.startListening({ language: 'en-US', continuous: true });
      }
    };

    startListening().catch((error) => console.log(error));
  }, [state]);

  if (!browserSupportsSpeechRecognition) {
    return (
      <IonRow className="ion-align-items-center">
        <IonCol>
          <IonLabel className="label-subject-simple">
            {t("This browser doesn't support speech recognition.")}
          </IonLabel>
        </IonCol>
      </IonRow>
    );
  }

  const showTranscript = window.location.host.includes('stg') && transcript;

  return <>{showTranscript}</>;
};

export default SpeechRecognitionComponent;
