import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './PercentageComponent.scoped.scss';
import React from 'react';

type PercentageComponentProps = {
  mode?: string;
  icon?: string;
  name?: string | null | undefined;
  answeredPercentage: number;
  answeredCorrectly: number;
  totalNoOfTrialsAnswered: number;
};

const PercentageComponent: React.FC<PercentageComponentProps> = (props) => {
  const { t } = useTranslation();
  const { mode, name, answeredPercentage, answeredCorrectly, totalNoOfTrialsAnswered } = props;

  const answeredPercentageOneDecimal = Math.round(answeredPercentage * 1000) / 10;

  return (
    <div className="results-circle">
      <CircularProgressbarWithChildren
        className={`circled-results ${mode ? 'session-results-percentage' : 'percentage'}`}
        strokeWidth={4}
        value={answeredPercentageOneDecimal}
        maxValue={100}
        styles={buildStyles({
          strokeLinecap: 'round',
        })}
      >
        <div className="results-summary">
          <div>
            {mode ? (
              <IonItem color="none" className="ion-no-margin ion-no-padding">
                <IonText className="session-results-participant-name">{name}</IonText>
              </IonItem>
            ) : (
              <IonItem
                color="none"
                className="ion-no-margin ion-no-padding ion-text-center participant-name"
              >
                <IonLabel className="name-label">{name}</IonLabel>
              </IonItem>
            )}
            <div color="none" className="ion-item-percentage">
              <IonText className={`${mode ? 'session-results-percentage' : 'percentage'}`}>
                {answeredPercentageOneDecimal}
                <span className="percent-sign">%</span>
              </IonText>
            </div>
          </div>
          <div>
            <IonItem color="none" className="ion-no-margin ion-no-padding ion-item-accuracy">
              <div className="accuracy-container">
                <div className={`${mode ? 'session-results-sense-accuracy' : 'sense-accuracy'}`}>
                  {t('Sense Accuracy')}
                </div>
                <div
                  className={`${mode ? 'session-results-number-of-answers' : 'number-of-answers'}`}
                >
                  {answeredCorrectly}/{totalNoOfTrialsAnswered}
                </div>
              </div>
            </IonItem>
          </div>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default PercentageComponent;
