import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import './LetsConnectLooker.scoped.scss';
import { push, replace } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { useAddLookerToSessionMutation } from '../../store/api';
import { Device } from '@capacitor/device';
import React, { useState } from 'react';
import checkmark from '../../images/checkmark.svg';
import scanQrCode from '../../images/qr-code-scan.svg';
import BackButton from '../../components/BackButton/BackButton';
import SessionCodeInput from '../../components/SessionCodeInput/SessionCodeInput';
import { setParticipant } from '../../store/slices';
import { TYPE_LOOKER } from '../../appConfigurations';

const LetsConnectLooker: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm();
  const [addLookerToSession] = useAddLookerToSessionMutation();
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: FieldValues) => {
    const sessionCode = Object.values(data).join('');
    setError('');

    setIsLoading(true);
    const info = await Device.getInfo();
    const userSettings = {
      type: TYPE_LOOKER,
      ready: false,
      play_sounds: false,
      year_of_birth: null,
      gender: null,
      latitude: null,
      longitude: null,
      feedback: null,
      name: info?.model,
      group: null,
      subject_looker_relationship: null,
    };

    if (sessionCode.length < 6) {
      setError(t('The code should be 6 characters long'));
      setIsLoading(false);
    } else {
      try {
        const response = await addLookerToSession({
          sessionId: sessionCode,
          userSettings,
          joinMethod: 'code',
        }).unwrap();

        if (response) {
          dispatch(setParticipant({ type: TYPE_LOOKER }));
          setMessage(t('Correct'));
          dispatch(replace('/looker-settings'));
        }
      } catch (error: any) {
        if (error.status === 403) {
          setError(t('Someone is already connected to this session'));
        }

        if (error.status === 404) {
          setError(t('This code is not associated with any session'));
        }

        if (error.status > 404) {
          setError(t('Error from server, please try again'));
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <IonPage>
      <IonContent className="looker-settings-bg">
        <IonList className="lets-connect-looker">
          <IonLoading isOpen={isLoading} spinner={'circular'} duration={5000} />
          <BackButton defaultPath="/new-session" />
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <h4 className="page-title">{t('Looker')}</h4>
          </IonItem>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <h4 className="page-subtitle">{t("Let's connect")}</h4>
          </IonItem>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <IonText className="subtitle">
              {t('Enter the code or scan the QR from the subject’s screen')}
            </IonText>
          </IonItem>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="code-form">
            <div>
              <SessionCodeInput register={register} message={message} error={error} />
              {error && (
                <div className="ion-text-center">
                  <span className="error-message">{error}</span>
                </div>
              )}
              {message && (
                <div className="ion-text-center success-message">
                  <IonIcon color="success" icon={checkmark} className="checkmark-icon" />
                  <span className="correct-code">{message}</span>
                </div>
              )}
            </div>
            <div className="buttons">
              <div className="keyboard-show">
                {isDirty && (
                  <IonButton className="primary-type-button" type="submit">
                    {t('Done')}
                  </IonButton>
                )}
              </div>
              <IonButton
                className="tertiary-type-button"
                onClick={() => dispatch(push('/scan-qr-looker'))}
              >
                <span className="btn-icon-container">
                  <IonIcon color="light" slot="start" icon={scanQrCode} className="scan-qr-icon" />
                  <span className="text">{t('Scan QR')}</span>
                </span>
              </IonButton>
            </div>
          </form>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default LetsConnectLooker;
