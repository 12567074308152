import ValidationWrapper from '../ErrorMessage/ValidationWrapper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputChangeEventDetail, IonIcon, IonInput } from '@ionic/react';
import eyeOff from '../../images/eye_off.svg';
import eyeOn from '../../images/eye_on.svg';

type AuthFormInputsProps = {
  register: any;
  errors: any;
  clearErrors: any;
  setValue?: any;
};

const AuthFormInputs: React.FC<AuthFormInputsProps> = ({
  register,
  errors,
  clearErrors,
  setValue,
}) => {
  const { t } = useTranslation();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const emailError =
    errors?.email?.type === 'required' || errors?.email?.type === 'server' || errors.server
      ? 'error-auth'
      : errors?.email?.type === 'pattern'
      ? 'warning-auth'
      : '';

  const passwordError =
    errors?.password?.type === 'required' || errors?.password?.type === 'server' || errors.server
      ? 'error-auth'
      : errors?.password?.type === 'pattern'
      ? 'warning-auth'
      : '';

  return (
    <>
      <ValidationWrapper errors={errors?.email}>
        <IonInput
          type="text"
          placeholder={t('Email')}
          className={`custom-input ${emailError} register-style`}
          onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
            setValue('email', e?.detail?.value || '');
            clearErrors('server');
          }}
          {...register('email', {
            required: 'The email field is required',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('Please enter a valid email address'),
            },
          })}
        />
      </ValidationWrapper>
      <ValidationWrapper errors={errors.password}>
        <IonInput
          type={passwordShown ? 'text' : 'password'}
          placeholder={t('Password')}
          className={`custom-input ${passwordError} register-style`}
          onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
            setValue('password', e?.detail?.value || '');
            clearErrors('server');
          }}
          {...register('password', {
            required: 'The password field is required',
          })}
        />
        <span className="password-visible-button" onClick={togglePassword}>
          <IonIcon src={passwordShown ? eyeOn : eyeOff} />
        </span>
      </ValidationWrapper>
      {errors.server && <ValidationWrapper errors={errors.server} />}
    </>
  );
};

export default AuthFormInputs;
