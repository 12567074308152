import SessionWrapper from '../../components/SessionWrapper/SessionWrapper';
import GetReady from '../GetReady/GetReady';
import { useSelector } from 'react-redux';
import { subjectSelector } from '../../store/slices';
import { replace } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';

const GetReadyLooker: React.FC = () => {
  const subject = useSelector(subjectSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <SessionWrapper
      subjectName={
        subject?.user?.name
          ? t("{{name}}'s {{device}}", { name: subject?.user?.name, device: subject?.name })
          : t(`Subject's {{device}}`, { device: subject?.name })
      }
    >
      <GetReady onDone={() => dispatch(replace('/looker-flow'))} lookerParticipant={true} />
    </SessionWrapper>
  );
};

export default GetReadyLooker;
