import {
  IonContent,
  IonPage,
  IonImg,
  IonList,
  IonText,
  IonItem,
  IonIcon,
  IonButton,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './NewSession.scoped.scss';
import { goBack, push } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import subjectIcon from '../../images/subject_icon.svg';
import lookerIcon from '../../images/looker_icon.svg';
import close from '../../images/close.svg';

const NewSession: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onSelectLooker = () => {
    dispatch(push('/lets-connect-looker'));
  };

  const onSelectSubject = () => {
    dispatch(push('/subject-settings'));
  };

  const onClose = () => {
    dispatch(goBack());
  };

  return (
    <IonPage>
      <IonContent className="new-session-content" forceOverscroll={false}>
        <IonList className="new-session-wrapper">
          <div className="close-container">
            <IonButton className="close-btn small-settings-button-dark" onClick={() => onClose()}>
              <div className="button-container">
                <IonIcon src={close} />
              </div>
            </IonButton>
          </div>
          <h4 className="page-title">{t('New session')}</h4>
          <IonText className="subtitle custom-subtitle">
            {t('Which role are you going to take?')}
          </IonText>
          <div className="roles">
            <IonItem lines="none" className="ion-no-padding item-role" onClick={onSelectSubject}>
              <div className="role-wrapper">
                <IonImg src={subjectIcon} alt="subject icon" className="role-image" />
                <h1 className="role">{t('Subject')}</h1>
                <div className="subtitle">{t('You will be tested how good your senses are')}</div>
              </div>
            </IonItem>
            <IonItem lines="none" className="ion-no-padding item-role" onClick={onSelectLooker}>
              <div className="role-wrapper looker">
                <IonImg src={lookerIcon} alt="looker icon" className="role-image" />
                <h1 className="role">{t('Looker')}</h1>
                <div className="subtitle">{t('You will test another person’s senses')}</div>
              </div>
            </IonItem>
            <IonText className="subtitle custom-subtitle">
              {t(
                'If you are the subject, please register first, and you will receive a QR code so the looker can connect with you.'
              )}
            </IonText>
          </div>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default NewSession;
