import BackButton from '../BackButton/BackButton';
import { IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import connectionSuccessfulIcon from '../../images/connection_successful.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';
import './ConnectionSuccessfulWrapper.scoped.scss';
import { TYPE_SUBJECT } from '../../appConfigurations';

type ConnectionSuccessfulProps = {
  children: React.ReactNode;
  openModal: Function;
  participantType: Number;
};

const ConnectionSuccessfulWrapper = ({
  children,
  openModal,
  participantType,
}: ConnectionSuccessfulProps) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent
        className={participantType === TYPE_SUBJECT ? 'subject-settings-bg' : 'looker-settings-bg'}
      >
        <IonGrid
          className={`connection-successful-wrapper grid-container page-wrapper ${
            participantType === TYPE_SUBJECT ? 'subject' : 'looker'
          }`}
        >
          <div className="header">
            <div className="btn-container">
              <BackButton conditionalNavigation={(back: Function) => openModal(back)} />
            </div>
            <h4 className="page-title">
              {participantType === TYPE_SUBJECT ? t('Subject') : t('Looker')}
            </h4>
          </div>
          <div className="body">
            <div className="content center-items-list">
              <IonRow class="icon-padding">
                <IonImg
                  src={connectionSuccessfulIcon}
                  alt="connection successful icon"
                  className="connection-icon"
                />
              </IonRow>
            </div>
            <IonRow className="ion-text-center connection-successful-text">
              <h4>{t('Connection successful')}</h4>
            </IonRow>
            {children}
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ConnectionSuccessfulWrapper;
