import { useIonToast } from '@ionic/react';

export const Toast = {
  showToast: (message: string) => {},
};

const ErrorHandler: React.FC = () => {
  const [present] = useIonToast();

  Toast.showToast = (message: string) => {
    present({
      message: message,
      duration: 1500,
      position: 'bottom',
      color: 'danger',
    });
  };

  return <></>;
};

export default ErrorHandler;
