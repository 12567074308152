import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AUTH_STATE_NAME, AuthState } from '../../models';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = ((getState() as any)[AUTH_STATE_NAME] as AuthState).token;
    headers.set('Accept', `application/json, text/plain, */*`);

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (process.env.NODE_ENV === 'development') {
      headers.set('ngrok-skip-browser-warning', '1');
    }

    return headers;
  },
});
