export const APP_SETTINGS_STATE_NAME = 'app-settings';

export interface AppSettingsState {
  numberOfTrials: number;
  timeBoxedTrial: boolean;
  trialDuration: number;
  playSounds: boolean;
  skipTrial: boolean;
  language: string;
  locationPermission: string;
}
