import React, { useEffect, useState } from 'react';
import {
  CheckboxChangeEventDetail,
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import './AppSettings.scoped.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import {
  numberOfTrialsOptions,
  trialDurationOptions,
  languageOptions,
  ItemType,
} from '../../appConfigurations';
import {
  setParticipant,
  homepageSelector,
  appSettingsSelector,
  setAppSettings,
} from '../../store/slices';
import { IonCheckboxCustomEvent, IonSelectCustomEvent } from '@ionic/core/dist/types/components';
import { SelectChangeEventDetail } from '@ionic/core';
import BackButton from '../../components/BackButton/BackButton';

const AppSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    numberOfTrials: numberOfTrialsStore,
    timeBoxedTrial: timeBoxedTrialStore,
    trialDuration: trialDurationStore,
    playSounds: playSoundsStore,
    skipTrial: skipTrialStore,
    language: languageStore,
  } = useSelector(appSettingsSelector);
  const [numberOfTrials, setNumberOfTrials] = useState(numberOfTrialsStore);
  const [timeBoxedTrial, setTimeBoxedTrial] = useState(timeBoxedTrialStore);
  const [trialDuration, setTrialDuration] = useState(trialDurationStore);
  const [playSounds, setPlaySounds] = useState(playSoundsStore);
  const [skipTrial, setSkipTrial] = useState(skipTrialStore);
  const [language, setLanguage] = useState(languageStore);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const homepage = useSelector(homepageSelector);

  const onSaveSettings = () => {
    dispatch(
      setAppSettings({
        numberOfTrials,
        timeBoxedTrial,
        trialDuration,
        playSounds,
        skipTrial,
        language,
      })
    );
    dispatch(setParticipant({ play_sounds: playSounds }));
    setShowToast(true);
    setToastMessage('Settings saved');
  };

  useEffect(() => {
    setNumberOfTrials(numberOfTrialsStore);
    setTimeBoxedTrial(timeBoxedTrialStore);
    setTrialDuration(trialDurationStore);
    setPlaySounds(playSoundsStore);
    setSkipTrial(skipTrialStore);
    setLanguage(languageStore);
  }, [
    numberOfTrialsStore,
    timeBoxedTrialStore,
    trialDurationStore,
    playSoundsStore,
    skipTrialStore,
    languageStore,
  ]);

  return (
    <IonPage>
      <IonContent className="app-settings-content">
        <IonList className="app-settings-wrapper">
          <BackButton defaultPath={homepage} />
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <h3 className="page-title">{t('Settings')}</h3>
          </IonItem>
          <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin">
            <IonText className="subtitle">{t('You can set everything here')}</IonText>
          </IonItem>
          <IonItem
            lines="none"
            color="none"
            className="ion-no-padding ion-no-margin settings-label"
          >
            <IonLabel>{t('Number of trials per session')}</IonLabel>
          </IonItem>
          <IonItem
            lines="none"
            color="none"
            className="ion-no-padding ion-no-margin custom-item-select custom-item-select-2"
          >
            <IonSelect
              interface="popover"
              value={numberOfTrials}
              onIonChange={(e: IonSelectCustomEvent<SelectChangeEventDetail>) =>
                setNumberOfTrials(e.detail.value)
              }
              className="select-settings-option"
            >
              {numberOfTrialsOptions?.length &&
                numberOfTrialsOptions.map((item: ItemType) => (
                  <IonSelectOption key={item.id} value={item.option}>
                    {t(item.text)}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonItem>
          <hr />
          <IonItem
            lines="none"
            color="none"
            className="ion-no-padding ion-no-margin settings-label item-with-checkbox"
          >
            <IonLabel>{t('Time-boxed trial')}</IonLabel>
            <IonCheckbox
              className="ion-no-margin"
              checked={timeBoxedTrial}
              onIonChange={(e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>) =>
                setTimeBoxedTrial(e.detail.checked)
              }
            />
          </IonItem>
          <hr />
          {timeBoxedTrial && (
            <>
              <IonItem
                lines="none"
                color="none"
                className="ion-no-padding ion-no-margin settings-label"
              >
                <IonLabel>{t('Trial duration')}</IonLabel>
              </IonItem>
              <IonItem lines="none" className="ion-no-padding ion-no-margin custom-item-select">
                <IonSelect
                  interface="popover"
                  value={trialDuration}
                  onIonChange={(e: IonSelectCustomEvent<SelectChangeEventDetail>) =>
                    setTrialDuration(e.detail.value)
                  }
                  className="select-settings-option"
                >
                  {trialDurationOptions?.length &&
                    trialDurationOptions.map((item) => (
                      <IonSelectOption
                        key={item.id}
                        value={item.option}
                        className="select-settings-option"
                      >
                        {item.text}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
              <hr />
            </>
          )}
          <IonItem
            lines="none"
            color="none"
            className="ion-no-padding ion-no-margin settings-label item-with-checkbox"
          >
            <IonLabel className="settings-label-text">{t('Sounds')}</IonLabel>
            <IonCheckbox
              className="ion-no-margin"
              checked={playSounds}
              onIonChange={(e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>) =>
                setPlaySounds(e.detail.checked)
              }
            />
          </IonItem>
          <hr />
          <IonItem
            lines="none"
            color="none"
            className="ion-no-padding ion-no-margin settings-label item-with-checkbox"
          >
            <IonLabel className="settings-label-text">{t('Skip trial option')}</IonLabel>
            <IonCheckbox
              className="ion-no-margin"
              checked={skipTrial}
              onIonChange={(e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>) =>
                setSkipTrial(e.detail.checked)
              }
            />
          </IonItem>
          <hr />
          <IonItem
            lines="none"
            color="none"
            className="ion-no-padding ion-no-margin settings-label"
          >
            <IonLabel>{t('Language')}</IonLabel>
          </IonItem>
          <IonItem lines="none" className="ion-no-padding ion-no-margin custom-item-select">
            <IonSelect
              interface="popover"
              value={language}
              onIonChange={(e: IonSelectCustomEvent<SelectChangeEventDetail>) =>
                setLanguage(e.detail.value)
              }
              className="select-settings-option"
            >
              {languageOptions?.length &&
                languageOptions.map((item) => (
                  <IonSelectOption key={item.id} value={item.option}>
                    {item.text}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter className="ion-no-border app-settings-footer">
        <IonToolbar className="ion-no-padding" color="none">
          <IonItem lines="none" color="none" className="ion-no-margin ion-no-padding">
            <IonButton className="primary-type-button center-button" onClick={onSaveSettings}>
              {t('Save')}
            </IonButton>
          </IonItem>
        </IonToolbar>
      </IonFooter>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={t(toastMessage)}
        duration={2000}
      />
    </IonPage>
  );
};

export default AppSettings;
