import { useSelector } from 'react-redux';
import { participantSelector, resetAll, startListeningSessionIdSelector } from '../store/slices';
import { useLookerCancelMutation, useSubjectCancelMutation } from '../store/api';
import { useEffect } from 'react';
import { Session, SessionIdRequest } from '../models';
import { TYPE_LOOKER } from '../appConfigurations';
import { useAppDispatch } from '../store/store';

export function useEndRunningSessions() {
  const startListeningSessionId = useSelector(startListeningSessionIdSelector);
  const [subjectCancel] = useSubjectCancelMutation();
  const [lookerCancel] = useLookerCancelMutation();
  const { type: participantType } = useSelector(participantSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkEndSession = async () => {
      if (startListeningSessionId) {
        let response: Session;
        if (participantType === TYPE_LOOKER) {
          response = await lookerCancel({
            sessionId: startListeningSessionId,
          } as SessionIdRequest).unwrap();
        } else {
          response = await subjectCancel({
            sessionId: startListeningSessionId,
          } as SessionIdRequest).unwrap();
        }
        if (response) {
          dispatch(resetAll());
        }
      }
    };
    checkEndSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startListeningSessionId]);
}
