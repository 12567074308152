import { createSelector, createSlice, Draft } from '@reduxjs/toolkit';
import { AUTH_STATE_NAME, AuthState, LoginResponse, User } from '../../models';
import { AuthApi } from '../api';

const ParticipantInitialState = {
  id: null,
  user: null,
  type: null,
  ready: false,
  play_sounds: true,
  year_of_birth: null,
  gender: null,
  latitude: null,
  longitude: null,
  feedback: null,
  group: null,
  name: null,
};

const clearLogin = (state: Draft<AuthState>) => {
  state.user = null;
  state.participant = ParticipantInitialState;
  state.token = null;
  state.isAuthenticated = false;
  state.allowShareLocation = false;
};

const handleAuthSuccess = (state: Draft<AuthState>, { payload }: { payload: LoginResponse }) => {
  state.token = payload.data.access_token;
  state.isAuthenticated = !!payload.data.access_token;
};

const updateUser = (state: Draft<AuthState>, { payload }: { payload: User }) => {
  state.user = {
    ...state.user,
    ...payload,
  };
};

export const AuthSlice = createSlice({
  name: AUTH_STATE_NAME,
  initialState: {
    user: null,
    participant: ParticipantInitialState,
    token: null,
    isAuthenticated: false,
    allowShareLocation: false,
  },
  reducers: {
    setParticipant: (state, { payload }) => {
      state.participant = {
        ...state.participant,
        ...payload,
      };
    },
    resetParticipant: (state) => {
      state.participant = ParticipantInitialState;
    },
    resetAuth: (state) => {
      state.user = null;
      state.participant = ParticipantInitialState;
      state.token = null;
      state.isAuthenticated = false;
    },
    setAllowShareLocation: (state, { payload }) => {
      state.allowShareLocation = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(AuthApi.endpoints.loginUser.matchPending, clearLogin)
      .addMatcher(AuthApi.endpoints.loginUser.matchFulfilled, handleAuthSuccess);
    builder.addMatcher(AuthApi.endpoints.getUser.matchFulfilled, updateUser);
    builder.addMatcher(AuthApi.endpoints.register.matchFulfilled, handleAuthSuccess);
    builder.addMatcher(AuthApi.endpoints.logOutUser.matchFulfilled, clearLogin);
    builder.addMatcher(AuthApi.endpoints.refreshToken.matchFulfilled, handleAuthSuccess);
    builder.addMatcher(AuthApi.endpoints.updateUserProfile.matchFulfilled, updateUser);
  },
});

type PartialState = {
  [AuthSlice.name]: AuthState;
  [key: string]: any;
};

export const userSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].user,
  (it) => it
);
export const tokenSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].token,
  (it) => it
);
export const isAuthenticatedSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].isAuthenticated,
  (it) => it
);
export const participantSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].participant,
  (it) => it
);
export const homepageSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].isAuthenticated,
  (isAuthenticated) => (isAuthenticated ? '/home' : '/welcome')
);
export const allowShareLocationSelector = createSelector(
  (state: PartialState) => state[AuthSlice.name].allowShareLocation,
  (it) => it
);

export const { setParticipant, resetParticipant, resetAuth, setAllowShareLocation } =
  AuthSlice.actions;
