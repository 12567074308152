import {
  IonContent,
  IonPage,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonList,
  IonInput,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useResetMutation } from '../../store/api';
import { PasswordResetRequest } from '../../models';
import { useAppDispatch } from '../../store/store';
import { push } from 'redux-first-history';
import BackButton from '../../components/BackButton/BackButton';
import { FieldValues, useForm } from 'react-hook-form';
import ValidationWrapper from '../../components/ErrorMessage/ValidationWrapper';
import { useParams } from 'react-router';
import './PasswordRecovery.scoped.scss';
import React from 'react';

const PasswordReset: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [reset] = useResetMutation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm();
  const { email, token } = useParams();

  const onSubmit = async (data: FieldValues) => {
    const { password, confirmPassword } = data || {};

    if (password !== confirmPassword) {
      setError('passwordsDontMatch', {
        type: 'password',
        message: t("The passwords don't match"),
      });
    } else {
      if (token && email) {
        try {
          const resetResult = await reset({
            email,
            password,
            password_confirmation: confirmPassword,
            token,
          } as PasswordResetRequest).unwrap();

          if (resetResult) {
            dispatch(push('/login'));
          }
        } catch (error: any) {
          if (error.status === 401) {
            setError('server', {
              type: 'server',
              message: error?.data?.errors,
            });
          }

          if (error.status === 422) {
            setError('server', {
              type: 'server',
              message: error?.data?.errors?.password,
            });
          }
        }
      }
    }
  };

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <IonGrid className="page-wrapper ion-no-padding">
          <BackButton />
          <IonRow>
            <IonCol>
              <h3>{t('Password reset')}</h3>
            </IonCol>
          </IonRow>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <IonList lines="none">
              <IonItem color="none" className="ion-no-margin ion-no-padding">
                <IonLabel>{t('Email')}</IonLabel>
              </IonItem>
              <IonInput className="custom-input" value={email} disabled={true} />
              <IonItem color="none" className="ion-no-margin ion-no-padding">
                <IonLabel>{t('New password')}</IonLabel>
              </IonItem>
              <ValidationWrapper errors={errors.password}>
                <IonInput
                  type="password"
                  className={`custom-input ${
                    errors.password || errors.server || errors.passwordsDontMatch
                      ? 'error-auth'
                      : ''
                  }`}
                  placeholder={t('Enter your new password')}
                  onIonChange={() => {
                    clearErrors('server');
                    clearErrors('passwordsDontMatch');
                  }}
                  {...register('password', {
                    required: 'The password field is required',
                  })}
                />
              </ValidationWrapper>
              <IonItem color="none" className="ion-no-margin ion-no-padding">
                <IonLabel>{t('Confirm new password')}</IonLabel>
              </IonItem>
              <ValidationWrapper errors={errors.confirmPassword}>
                <IonInput
                  type="password"
                  className={`custom-input ${
                    errors.confirmPassword || errors.server || errors.passwordsDontMatch
                      ? 'error-auth'
                      : ''
                  }`}
                  placeholder={t('Confirm your new password')}
                  onIonChange={() => {
                    clearErrors('server');
                    clearErrors('passwordsDontMatch');
                  }}
                  {...register('confirmPassword', {
                    required: 'The password field is required',
                  })}
                />
              </ValidationWrapper>
              {errors.server && <ValidationWrapper errors={errors.server} />}
              {errors.passwordsDontMatch && (
                <ValidationWrapper errors={errors.passwordsDontMatch} />
              )}
              <IonItem color="none" className="ion-no-margin ion-no-padding ion-padding-top">
                <IonButton className="primary-type-button" type="submit">
                  {t('Reset')}
                </IonButton>
              </IonItem>
            </IonList>
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PasswordReset;
