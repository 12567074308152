import React from 'react';
import { push, replace, goBack, RouterState } from 'redux-first-history';
import { currentRouteSelector, useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import { IonButton, IonImg, IonItem } from '@ionic/react';
import backIcon from '../../images/back_button.svg';
import './BackButton.scoped.scss';

export interface DropdownProps {
  defaultPath?: string;
  conditionalNavigation?: Function;
}

const BackButton: React.FC<DropdownProps> = ({ defaultPath, conditionalNavigation }) => {
  const dispatch = useAppDispatch();
  const { previousLocations }: RouterState = useSelector(currentRouteSelector);

  const onBack = () => {
    if (previousLocations && previousLocations.length > 2) {
      dispatch(goBack());
    } else if (defaultPath) {
      dispatch(replace(defaultPath));
    } else {
      dispatch(push('/welcome'));
    }
  };

  const handleClick = () => {
    if (conditionalNavigation) {
      conditionalNavigation(onBack);
    } else {
      onBack();
    }
  };

  return (
    <IonItem lines="none" color="none" className="ion-no-padding ion-no-margin back-button">
      <IonButton
        className="small-settings-button-light"
        onClick={handleClick}
        routerDirection="back"
      >
        <IonImg className="back-icon" src={backIcon} alt="back button icon" />
      </IonButton>
    </IonItem>
  );
};

export default BackButton;
