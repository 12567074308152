const GENDER_NA = 0;
const GENDER_MALE = 1;
const GENDER_FEMALE = 2;
const SUBJECT_LOOKER_RELATIONSHIP_FRIEND = 1;
const SUBJECT_LOOKER_RELATIONSHIP_FAMILY = 2;
const SUBJECT_LOOKER_RELATIONSHIP_STRANGER = 3;
const SUBJECT_LOOKER_RELATIONSHIP_PARTNER = 4;
const SUBJECT_LOOKER_RELATIONSHIP_COLLEAGUE = 5;
const SUBJECT_LOOKER_RELATIONSHIP_NA = 0;
export const STATUS_SESSION_CREATED = 10;
export const STATUS_SESSION_COMPLETE = 100;
export const LOOKER_JOINED = 20;
export const STATUS_IN_PROGRESS = 40;
export const STATUS_INTERRUPTED = 60;
export const REQUEST_LOOP_TIMEOUT = 500;
export const SUBJECT_ANSWER_NO = 0;
export const SUBJECT_ANSWER_YES = 1;
export const SUBJECT_ANSWER_SKIP = 3;
export const TYPE_SUBJECT = 0;
export const TYPE_LOOKER = 1;
export const DONT_WATCH_SUBJECT = 0;
export const WATCH_SUBJECT = 1;
export const MINIMUM_CONTACT_US_PERCENTAGE = 0.65;
export const MINIMUM_NUMBER_OF_SESSIONS = 10;
export const STATUS_LOOKER_CANCELED = 50;
export const STATUS_SUBJECT_CANCELED = 60;

export const numberOfTrialsOptions = [
  {
    id: 1,
    option: 20,
    text: '20 trials (Default)',
  },
  {
    id: 2,
    option: 10,
    text: '10 trials',
  },
  {
    id: 3,
    option: 40,
    text: '40 trials',
  },
];

export const trialDurationOptions = [
  {
    id: 1,
    option: 8,
    text: '8 seconds (Default)',
  },
  {
    id: 2,
    option: 15,
    text: '15 seconds',
  },
  {
    id: 3,
    option: 20,
    text: '20 seconds',
  },
];

export const languageOptions = [
  {
    id: 1,
    option: 'en-US',
    text: 'English',
  },
];

export const genderOptions = [
  {
    id: 1,
    option: GENDER_MALE,
    text: 'Male',
  },
  {
    id: 2,
    option: GENDER_FEMALE,
    text: 'Female',
  },
  {
    id: 3,
    option: GENDER_NA,
    text: 'Other/Prefer not to say',
  },
];

export const subjectLookerRelationshipOptions = [
  {
    id: 1,
    option: SUBJECT_LOOKER_RELATIONSHIP_FRIEND,
    text: 'Friends',
  },
  {
    id: 2,
    option: SUBJECT_LOOKER_RELATIONSHIP_FAMILY,
    text: 'Family',
  },
  {
    id: 3,
    option: SUBJECT_LOOKER_RELATIONSHIP_STRANGER,
    text: 'Strangers',
  },
  {
    id: 4,
    option: SUBJECT_LOOKER_RELATIONSHIP_PARTNER,
    text: 'Partners',
  },
  {
    id: 5,
    option: SUBJECT_LOOKER_RELATIONSHIP_COLLEAGUE,
    text: 'Colleagues',
  },
  {
    id: 6,
    option: SUBJECT_LOOKER_RELATIONSHIP_NA,
    text: 'NA',
  },
];

export type ItemType = {
  id: number;
  option: number;
  text: string;
};

export const allowShareLocation = (isChecked: boolean, setValue: Function) => {
  if (isChecked) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position?.coords;

        setValue('latitude', latitude.toString());
        setValue('longitude', longitude.toString());
      });
    } else {
      alert('Geolocation is not supported by this browser');
    }
  } else {
    setValue('latitude', null);
    setValue('longitude', null);
  }
};

export const allowMicrophone = (setValue: Function) => {
  if (navigator.mediaDevices) {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream: MediaStream) => {
        if (stream) {
          setValue(true);
        }
      })
      .catch((err) => {
        setValue(false);
      });
  } else {
    alert('Microphone is not supported by this browser');
  }
};

const currentYear = new Date().getFullYear();
const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
export const yearOptions = range(currentYear, currentYear - 120, -1);
