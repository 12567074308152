import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login/Login';
import NewSession from './pages/NewSession/NewSession';
import NewSessionGuest from './pages/NewSessionGuest/NewSessionGuest';
import Home from './pages/Home/Home';
import SplashScreen from './pages/SplashScreen/SplashScreen';
import Register from './pages/Register/Register';
import PasswordRecovery from './pages/PasswordRecovery/PasswordRecovery';
import PasswordReset from './pages/PasswordRecovery/PasswordReset';
import Profile from './pages/Profile/Profile';
import Welcome from './pages/WelcomeScreen/Welcome';
import AnimatedSwitch, { AnimatedSwitchProps } from './components/AnimatedSwitch/AnimatedSwitch';
import { currentRouteSelector } from './store/store';
import { useSelector } from 'react-redux';
import { RouterState } from 'redux-first-history';
import AppSettings from './pages/AppSettings/AppSettings';
import LetsConnectLooker from './pages/LetsConnectLooker/LetsConnectLooker';
import LetsConnectSubject from './pages/LetsConnectSubject/LetsConnectSubject';
import SubjectSettings from './pages/SubjectSettings/SubjectSettings';
import LookerSettings from './pages/LookerSettings/LookerSettings';
import ScanTheQr from './pages/ScanTheQr/ScanTheQr';
import ConnectionSuccessfulSubject from './pages/ConnectionSuccessfulSubject/ConnectionSuccessfulSubject';
import ConnectionSuccessfulLooker from './pages/ConnectionSuccessfulLooker/ConnectionSuccessfulLooker';
import SessionIsStartingSubject from './pages/SessionIsStartingSubject/SessionIsStartingSubject';
import SessionIsStartingLooker from './pages/SessionIsStartingLooker/SessionIsStartingLooker';
import SubjectFlow from './pages/SubjectFlow/SubjectFlow';
import WaitForNextTrial from './pages/WaitForNextTrial/WaitForNextTrial';
import EndOfSession from './pages/EndOfSession/EndOfSession';
import GetReadySubject from './pages/GetReadySubject/GetReadySubject';
import GetReadyLooker from './pages/GetReadyLooker/GetReadyLooker';
import LookerFlow from './pages/LookerFlow/LookerFlow';
import SubjectAnswered from './pages/SubjectAnswered/SubjectAnswered';
import SessionResults from './pages/SessionResults/SessionResults';
import HowOurAppWorks from './pages/HowOurAppWorks/HowOurAppWorks';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import SessionFullDetails from './pages/SessionFullDetails/SessionFullDetails';
import { homepageSelector } from './store/slices';

export const AppRouter: React.FC = () => {
  const { location, action }: RouterState = useSelector(currentRouteSelector);
  const animatedSwitchAttributes: AnimatedSwitchProps = { location, action };
  const homepage = useSelector(homepageSelector);

  return (
    <AnimatedSwitch {...animatedSwitchAttributes}>
      <Routes location={location as Partial<Location>}>
        <Route path="/splash-screen" element={<SplashScreen />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/recovery" element={<PasswordRecovery />} />
        <Route path="/reset/:email/:token" element={<PasswordReset />} />
        <Route path="/new-session" element={<NewSession />} />
        <Route path="/new-session-as-guest" element={<NewSessionGuest />} />
        <Route
          path="/my-profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="/settings" element={<AppSettings />} />
        <Route path="/lets-connect-looker" element={<LetsConnectLooker />} />
        <Route path="/lets-connect-subject" element={<LetsConnectSubject />} />
        <Route path="/subject-settings" element={<SubjectSettings />} />
        <Route path="/looker-settings" element={<LookerSettings />} />
        <Route path="/scan-qr-looker" element={<ScanTheQr />} />
        <Route path="/connection-successful-subject" element={<ConnectionSuccessfulSubject />} />
        <Route path="/connection-successful-looker" element={<ConnectionSuccessfulLooker />} />
        <Route path="/subject-ready" element={<SessionIsStartingSubject />} />
        <Route path="/looker-ready" element={<SessionIsStartingLooker />} />
        <Route path="/get-ready-subject" element={<GetReadySubject />} />
        <Route path="/get-ready-looker" element={<GetReadyLooker />} />
        <Route path="/wait-for-next-trial" element={<WaitForNextTrial />} />
        <Route path="/subject-flow" element={<SubjectFlow />} />
        <Route path="/end-of-session" element={<EndOfSession />} />
        <Route path="/looker-flow" element={<LookerFlow />} />
        <Route path="/subject-answered" element={<SubjectAnswered />} />
        <Route path="/results" element={<SessionResults />} />
        <Route path="/how-our-app-works" element={<HowOurAppWorks />} />
        <Route
          path="/session/:id"
          element={
            <ProtectedRoute>
              <SessionFullDetails />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to={homepage} replace />} />
      </Routes>
    </AnimatedSwitch>
  );
};
