import React from 'react';
import { IonIcon, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './SessionBasicDetails.scoped.scss';
import backIcon from '../../images/back_button.svg';
import { Session, SessionExtraKeys } from '../../models';
import moment from 'moment';
import { push } from 'redux-first-history';
import { useAppDispatch } from '../../store/store';
import completeIcon from '../../images/completeIcon.svg';
import { STATUS_SESSION_COMPLETE } from '../../appConfigurations';

type SessionBasicDetailsProps = {
  session: Session;
};

const SessionBasicDetails: React.FC<SessionBasicDetailsProps> = ({ session }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const finishedDate = moment(session?.updated_at).format('llll');

  let sessionWithExtraKeys = {
    ...session,
    statusIcon: session.status >= STATUS_SESSION_COMPLETE ? completeIcon : '',
  } as SessionExtraKeys;

  return (
    <div
      className="session-details-wrapper"
      onClick={() => dispatch(push(`/session/${sessionWithExtraKeys.id}`))}
    >
      <div className="session-details-header">
        <IonText className="session-header">{t(`Session 00${sessionWithExtraKeys.id}`)}</IonText>
        <IonIcon color="primary" src={backIcon} className="go-forward-button" />
      </div>
      <IonText className="finished-date">{finishedDate}</IonText>
      <div className="results-wrapper">
        <IonText className="subject-header">{t('Results')}</IonText>
        <IonText className="session-details-results">
          {Math.round(session.answered_percentage * 1000) / 10}%
          <span className="results-separator">・</span>
          {session.answered_correctly}/{session.total_no_of_trials_answered}
        </IonText>
        <IonIcon src={sessionWithExtraKeys.statusIcon} className="status-icon-size" />
      </div>
    </div>
  );
};

export default SessionBasicDetails;
