import {
  IonContent,
  IonPage,
  IonButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCheckbox,
  IonItem,
  IonCol,
  IonText,
  CheckboxChangeEventDetail,
} from '@ionic/react';
import React from 'react';
import './Register.scoped.scss';
import { useTranslation } from 'react-i18next';
import { useRegisterMutation } from '../../store/api';
import { RegisterRequest } from '../../models';
import { useAppDispatch } from '../../store/store';
import { loadUser } from '../../store/actions';
import { push } from 'redux-first-history';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import AuthFormInputs from '../../components/AuthFormInputs/AuthFormInputs';
import { Device } from '@capacitor/device';
import ValidationWrapper from '../../components/ErrorMessage/ValidationWrapper';

const Register: React.FC = () => {
  const { t } = useTranslation();
  const [registerUser] = useRegisterMutation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    control,
    setValue,
  } = useForm();

  const onSubmit = async (data: FieldValues) => {
    const { email, password, checkbox } = data || {};
    const userName = await Device.getInfo();

    try {
      const registerResult = await registerUser({
        name: userName?.model,
        email: email,
        password: password,
        terms_of_service: checkbox,
        appId: process.env.REACT_APP_CLIENT_ID,
      } as RegisterRequest).unwrap();

      if (registerResult) {
        await dispatch(loadUser());
        dispatch(push('/home'));
      }
    } catch (error: any) {
      if (error.status === 422) {
        setError('email', {
          type: 'server',
          message: error?.data?.errors.email,
        });
        setError('password', {
          type: 'server',
          message: error?.data?.errors.password,
        });
      }

      if (error.status > 422) {
        setError('server', {
          type: 'server',
          message: t('Error from server, please try again'),
        });
      }
    }
  };

  return (
    <IonPage>
      <IonContent forceOverscroll={false}>
        <IonGrid className="page-wrapper grid-container auth-content-wrapper ion-no-padding">
          <div className="register-content">
            <IonRow>
              <IonCol className="under-register-login-text ion-no-padding ion-no-margin">
                <IonText>
                  <h4 className="page-title">{t('Register')}</h4>
                </IonText>
                <IonText className="subtitle-auth">
                  {t('Create an account and let’s start testing your sense!')}
                </IonText>
              </IonCol>
            </IonRow>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <AuthFormInputs
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                setValue={setValue}
              />
              <ValidationWrapper errors={errors.checkbox}>
                <IonRow className="ion-padding-bottom">
                  <IonItem lines="none" className="item-with-checkbox">
                    <Controller
                      control={control}
                      name="checkbox"
                      render={({ field: { onChange, value } }) => (
                        <IonCheckbox
                          slot="start"
                          checked={value}
                          onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) => {
                            onChange(e?.detail.checked);
                          }}
                        />
                      )}
                      defaultValue={false}
                      rules={{
                        required: 'The checkbox field is required',
                      }}
                    />
                    <IonLabel className="agreement-text">
                      {t('I agree to the Terms of Service and Privacy Policy')}
                    </IonLabel>
                  </IonItem>
                </IonRow>
              </ValidationWrapper>
              <IonRow class="ion-padding-top">
                <IonButton className="primary-type-button filled-button" type="submit">
                  {t('REGISTER NOW')}
                </IonButton>
              </IonRow>
            </form>
          </div>
          <IonRow className="ion-justify-content-center login-box">
            <IonText className="not-a-member">{t('Already have an account?')}</IonText>
            <IonText className="register-or-login" onClick={() => dispatch(push('login'))}>
              {t('Log in')}
            </IonText>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Register;
